import { FC, Fragment, useState } from "react";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FilterParamProps } from "./Toolbar";
interface Props {
    filterParam: FilterParamProps
}
interface StateProps {
    values: string[]
}
const MultiSelect: FC<Props> = ({
    filterParam
}) => {
    const [state, setState] = useState<StateProps>({
        values: []
    })
    const handleOnChange = (_: React.ChangeEvent<{}>, values: string[]) => {
        setState({ ...state, values })
    };
    return (
        <Fragment>
            <Autocomplete
                sx={{ m: 1, width: 300, mb: 2 }}
                multiple
                options={filterParam.options as string[]}
                getOptionLabel={(option) => option}
                disableCloseOnSelect
                defaultValue={filterParam.selected as string[]}
                onChange={handleOnChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={filterParam.label}

                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <MenuItem
                        {...props}
                        key={option}
                        value={option}
                        sx={{ justifyContent: "space-between" }}
                    >
                        {option}
                        {selected ? <CheckIcon color="info" /> : null}
                    </MenuItem>
                )}
            />
            <input hidden name={filterParam.field} value={state.values} />
        </Fragment>
    );
}
export default MultiSelect 