import { Box, Button, TextField, Typography } from "@mui/material";
import PageTitle from "../Common/PageTitle";
import { FC, useEffect, useState } from "react";
import { Apis } from "../../helpers";
import { EventsEmit } from "../../configs/events";
import { useNavigate } from "react-router-dom";
import { Connect, mapDispatchToProps, mapStateToProps } from "../../Redux";

interface AccountProps {
  bankName: string;
  accountNumber: string;
}
interface Props {
  setRedux?: (data: any) => void;
  User?: any;
}

const Account: FC<Props> = ({
  User
}) => {
  const payments = [
    {
      name: "KCB",
      logo: "https://images.africanfinancials.com/ug-kcb-logo.png",
      value: "",
    },
    {
      name: "Equity",
      logo: "https://w7.pngwing.com/pngs/93/287/png-transparent-equity-bank-kenya-limited-equity-group-holdings-limited-uganda-bank-text-service-logo-thumbnail.png",
      value: "",
    },
    {
      name: "Mpesa",
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/M-PESA_LOGO-01.svg/1200px-M-PESA_LOGO-01.svg.png",
      value: "",
    },
  ];
  const [state, setState] = useState({
    accounts: payments.map((payment) => ({
      bankName: payment.name,
      accountNumber: "",
    })),
    plan:""
  });
  const { accounts, plan } = state;
  const navigate = useNavigate();

  const handleAccNumChange = (index: number, value: string) => {
    const newAccounts = [...accounts];
    newAccounts[index].accountNumber = value;
    setState({ ...state, accounts: newAccounts });
  };
  const handleSubmit = () => {
    const data = accounts;
    Apis({
      module: "Users",
      action: "addUpdatePaymentOpt",
      userId:User._id,
      data,
    }).then((resp: any) => {
    // console.log({resp});
    if(resp.message)
    EventsEmit("app", {
      info: {
        message:resp.message,
        status: "success",
      },
    });
    navigate("/properties");
    
    })
  };
  const fetchAccounts = async () => {
    const resp:any = await Apis({
      module: "Users",
      action: "fetchPaymentOpt",
      userId:User._id,
    });
    const updatedAccounts = accounts.map((account, index) => ({
      ...account,
      accountNumber: resp.paymentOpts[index]?.accountNumber || ''
    }))
    setState({
     ...state,
     accounts: updatedAccounts,
     plan:resp.plan
    });
  }
  useEffect(() => {
    fetchAccounts();
  
    return () => {
    }
  }, [])
  
  return (
    <Box>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Box
          sx={{  pl: 2, pr: 2, borderRadius: 10 }}
        >
          {plan}
        </Box>
        <Button variant={"outlined"} sx={{}}>
          Upgrade
        </Button>
      </Box>

      <Typography
        sx={{
          fontWeight: "bold",
          mt: 10,
          mb: 5,
        }}
      >
        My Payment Options
      </Typography>
      <Box component="form" sx={{ mt: 1 }}>
        {payments.map(({ logo, name, value }, i) => (
          <Box
            key={i}
            sx={{
              mt: 5,
              width: 500,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <img
                alt="logo"
                src={logo}
                style={{ height: 50, width: 100, objectFit: "cover" }}
              />
              <Typography sx={{ ml: 2 }}>{name}</Typography>
            </Box>
            <TextField
              label={"Account Number"}
              type={"number"}
              value={accounts[i].accountNumber}
              onChange={(e) => handleAccNumChange(i, e.target.value)}
            />
          </Box>
        ))}
        <Button variant={"contained"} sx={{ textTransform: "none", mt: 10 }}
        onClick={handleSubmit}
        disabled={accounts.every((account) =>!account.accountNumber)}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default  Connect(mapStateToProps, mapDispatchToProps)(Account);