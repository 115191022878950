import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import PageTitle from "../../Common/PageTitle";
import { LoadingButton } from "@mui/lab";
import { InputProps } from "../../../types";

interface StateProps {
  inputs: InputProps[];
  sending: boolean;
}
interface Props {}

const NewUserRole: FC<Props> = () => {
  const initialInputs: InputProps[] = [
    { label: "Name", value: "Denis Mwaniki", field: "", required: true },
    { label: "Role", value: "Accountant", field: "", required: true },
  ];
  const formRef = useRef(null);

  const [state, setState] = useState<StateProps>({
    inputs: initialInputs,
    sending: false,
  });
  const { inputs, sending } = state;
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  return (
    <Box sx={{ height: "100%", pr: 5 }}>
      <PageTitle backBtn title={"New UserRole"} />
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          pt: 10,
          ml: 10,
        }}
      >
        <Box
          component="form"
          ref={formRef}
          onSubmit={handleSubmit}
          sx={{ mt: 1 }}
        >
          <Grid container spacing={3}>
            {inputs.map(
              (
                { label, type, required, value, field, error, options },
                i: number
              ) => (
                <Grid
                  item
                  xs={12}
                  sm={["Message", "Subject"].includes(label) ? 12 : 6}
                  key={field}
                >
                  <TextField
                    autoComplete="given-name"
                    name={field}
                    disabled={["to", "from"].includes(field)}
                    required={required}
                    defaultValue={value}
                    fullWidth
                    label={label}
                    type={type || "text"}
                    autoFocus
                    error={Boolean(error)}
                    helperText={error}
                    maxRows={10}
                    minRows={5}
                    multiline={label === "Message"}
                  />
                </Grid>
              )
            )}
          </Grid>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, textTransform: "none" }}
            loading={sending}
          >
            Add
          </LoadingButton>
        </Box>
      </Container>
    </Box>
  );
};
export default NewUserRole;
