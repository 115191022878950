const columns = [
    {
        label: 'Date',
        name: 'dateAdded',
        isDate: true
    },
    {
        label: 'Unit',
        field: 'name',
        name: 'unit'
    },
    {
        label: 'Tenant',
        field: 'name',
        name: 'tenant'
    },
    {
        label: 'Unit Label / House No.',
        field: 'unitLabel',
        name: 'unitLabel'
    },
    {
        label: 'Title',
        name: 'description'
    },
    {
        label: 'Description',
        name: 'notes'
    },
    {
        label: 'Amount (Kes)',
        name: 'amount',
        isNumber: true
    },
    {
        label: 'Due Date',
        name: 'dueDate',
        isDate: true
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns