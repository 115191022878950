import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { appName } from '../../../configs';
import lightTheme from '../../../theme/light';
import { AccountCircle, ApartmentOutlined, BookmarkRemoveOutlined, EngineeringOutlined, ExpandMore, FactCheckOutlined, HotelOutlined, LocalOfferOutlined, LocationCityOutlined, LogoutOutlined, MailOutline, PaymentsOutlined, PendingActionsOutlined, PeopleOutline, PowerOutlined, QueryStatsOutlined, RequestQuoteOutlined, Settings, ViewQuiltOutlined } from '@mui/icons-material'
import { Connect, mapDispatchToProps, mapStateToProps } from '../../../Redux';
import { Avatar, Button, Link, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { PropertyProps } from '../../../types/properties';
import MenuListPop from '../menu';
import logo from '../../../assets/images/logo.png'
import whiteLogo from '../../../assets/images/whiteLogo.png'
import AlertDialogSlide from '../alert';
import { UserProps } from '../../../types/user';
export const terms_urls = ['/terms_conditions', '/privacy_policies', '/faqs', '/contact_us']
const { pathname } = window.location

const drawerWidth = terms_urls.includes(pathname) ? 0 : 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
interface Props {
  selectedProperty?: PropertyProps
  properties?: PropertyProps[]
  drawerOpen?: boolean
  User?: UserProps
  setRedux?: (data: any) => void
  logOut?: () => void
}
interface StateProps {
  showAlert: boolean
}

const MiniDrawer: React.FC<Props> = ({
  selectedProperty,
  properties,
  drawerOpen,
  User,
  setRedux,
  logOut
}) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const open = drawerOpen
  const [state, setState] = React.useState<StateProps>({
    showAlert: false
  })
  const { showAlert } = state

  const listOptions = [
    { label: 'Dashboard', Icon: <ViewQuiltOutlined />, page: '/dashboard' },
    // { label: 'Messages', Icon: <MailOutline />, page: '/messages' },
    { label: 'Units', Icon: <ApartmentOutlined />, page: '/units', subUrl: ['/new_unit'] },
    { label: 'Units Labels', Icon: <LocalOfferOutlined />, page: '/units_labels', subUrl: ['/new_unitLabel'] },
    { label: 'Utilities & Amenities', Icon: <PowerOutlined />, page: '/utilities', subUrl: ['/new_utility'] },
    { label: 'Tenants', Icon: <PeopleOutline />, page: '/tenants', subUrl: ['/new_tenant', '/tenant', '/new_email'] },
    // { label: 'BnBs', Icon: <HotelOutlined />, page: '/bnb' },
    { label: 'Maintenances', Icon: <EngineeringOutlined />, page: '/maintenances', subUrl: ['/new_maintenance'] }
  ]
  const listOptions0 = [
    { label: 'Deductions', Icon: <BookmarkRemoveOutlined />, page: '/deductions', subUrl: [] },
    { label: 'Arrears', Icon: <PendingActionsOutlined />, page: '/arrears', subUrl: ['/new_arrear'] },
    { label: 'Invoices', Icon: <RequestQuoteOutlined />, page: '/invoices', subUrl: ['/new_invoice'] },
    { label: 'Payments', Icon: <PaymentsOutlined />, page: '/payments', subUrl: ['/new_payment'] },
    { label: 'Expenditures', Icon: <FactCheckOutlined />, page: '/expenditures' },
    { label: 'Account Settings', Icon: <Settings />, page: '/account_settings' },

  ]
  const rightItems = [
    { label: 'Properties', page: '/properties', Icon: <LocationCityOutlined sx={{ color: '#fff' }} /> },
    { label: 'Statistics', page: '/statistics', Icon: <QueryStatsOutlined sx={{ color: '#fff' }} /> },
    {
      label: 'My Account', Icon: <MenuListPop
        profile
        menuList={[
          { label: 'My Profile', icon: <AccountCircle /> },
          { label: 'Logout', icon: <LogoutOutlined /> }
        ]}
        onSelect={(option) => {
          if (option === 'Logout' && logOut) {
            logOut()
           window.location.href = '/landing.html'
          }
          else
            navigate('/account_settings')
        }}
      />
    },
  ]

  const location = useLocation()

  const handleDrawerAction = () => {
    if (setRedux)
      setRedux({
        drawerOpen: !drawerOpen
      })
  };
  const showTermsOrPolicies = terms_urls.includes(pathname)

  return (
    User || showTermsOrPolicies ?
      <Box sx={{
        display: 'flex'
      }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerAction}
                edge="start"
                sx={{
                  mr: 5,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {
                !open || showTermsOrPolicies ?
                  <Link sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                    href={'/'}
                  >
                    <img
                      alt='logo'
                      src={whiteLogo} style={{ height: 50, width: 50, objectFit: 'contain' }} />
                    <Typography
                      color={'#fff'}
                      sx={{ fontSize: 26, ml: 1 }}
                    >{appName}</Typography>
                  </Link> :
                  <React.Fragment />
              }
            </Box>
            {
              !showTermsOrPolicies ?

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                  {
                    rightItems.map(({ Icon, label, page }) => (

                      label === 'My Account' ?
                        <React.Fragment key={label}>
                          {Icon}
                        </React.Fragment>
                        :
                        <Tooltip key={label} title={label}>
                          <IconButton onClick={() => navigate(page as string)}>
                            {Icon}
                          </IconButton>
                        </Tooltip>
                    ))
                  }
                </Box> :
                <React.Fragment />
            }
          </Toolbar>
        </AppBar>
        {
          showTermsOrPolicies ?
            <React.Fragment /> :
            <Drawer
              variant="permanent"
              open={open}
            >
              <DrawerHeader sx={{ pl: 1 }}>
                <img src={logo} style={{ height: 50, width: 50, objectFit: 'contain' }} alt='logo' />
                <Typography
                  color={lightTheme.palette.primary.main}
                  sx={{ fontSize: 26 }}
                >{appName}</Typography>
                <IconButton onClick={handleDrawerAction}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </DrawerHeader>
              <Divider sx={{ ml: 2, mr: 2 }} />
              <Button color={'secondary'} sx={{
                color: '#000',
                textTransform: 'none',
                fontSize: 16,
                fontWeight: selectedProperty ? 'bold' : 'normal',
                mt: 3,
                mb: 3
              }}
                onClick={() => navigate('/properties')}
              >
                <Avatar src={selectedProperty?.logo} sx={{ mr: 1, height: 30, width: 30 }} />
                {!open ? null :
                  <React.Fragment>
                    <Tooltip title={selectedProperty?.name}>
                      <Box sx={{
                        width: 150,
                        display: 'flex',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}>
                        {selectedProperty?.name || 'Select Property'}
                      </Box>
                    </Tooltip>
                    {/* <ExpandMore sx={{ ml: 1,opacity:0 }} /> */}
                  </React.Fragment>
                }
              </Button>
              {/* <Divider sx={{ ml: 2, mr: 2 }} /> */}
              {
                [listOptions, listOptions0].map((options, i) => (
                  <React.Fragment key={i}>
                    {
                      i === 1 ?
                        <Divider sx={{ ml: 2, mr: 2 }} /> :
                        null
                    }
                    <List>
                      {options.map(({ label, Icon, page, subUrl }, index) => (
                        <ListItem
                          key={label}
                          disablePadding
                          sx={{
                            display: 'block',
                            bgcolor: (
                              page === location.pathname ||
                              subUrl?.includes(location.pathname)
                            ) ? theme.palette.primary.contrastText : 'transparent'
                          }}>
                          <Tooltip title={open ? '' : label}>
                            <ListItemButton
                              sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                              }}
                              onClick={() => {
                                let Page = page
                                if ((!selectedProperty) && !['/account_settings'].includes(page))
                                  Page = '/properties'
                                navigate(Page)
                                if (!selectedProperty)
                                  setState({ ...state, showAlert: true })
                              }}
                            >
                              <ListItemIcon
                                sx={{
                                  minWidth: 0,
                                  mr: open ? 3 : 'auto',
                                  justifyContent: 'center',
                                }}
                              >
                                {Icon}
                              </ListItemIcon>
                              <ListItemText primary={label} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                          </Tooltip>
                        </ListItem>
                      ))}
                    </List>
                  </React.Fragment>
                ))
              }
            </Drawer>
        }
        {
          showAlert ?
            <AlertDialogSlide
              title='Select Property'
              message='Please set a property to default before using this feature.'
              close={() => setState({ ...state, showAlert: false })}
            /> :
            <React.Fragment />
        }
      </Box> :
      <React.Fragment />
  );
}
export default Connect(mapStateToProps, mapDispatchToProps)(MiniDrawer)