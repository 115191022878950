import { Box, Container, Grid, TextField } from "@mui/material"
import PageTitle from "../../Common/PageTitle"
import { InputProps } from "../../../types"
import { FC, useRef, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { Connect, mapStateToProps } from "../../../Redux"
import { PropertyProps } from "../../../types/properties"
import { useLocation, useNavigate } from "react-router-dom"
import { Apis } from "../../../helpers"
import { EventsEmit } from "../../../configs/events"
interface StateProps {
    inputs: InputProps[]
    sending: boolean
}
interface Props {
    selectedProperty?: PropertyProps
}
const NewEmail: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { user } = location?.state || {}
    const initialInputs: InputProps[] = [
        { label: 'To', value: user.email, field: 'to', required: true },
        { label: 'From', value: selectedProperty?.name + ' Management', field: 'from', required: true },
        { label: 'Subject', value: '', field: 'subject', required: true },
        { label: 'Message', value: '', field: 'message', required: true },
    ]
    const formRef = useRef(null)

    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        sending: false
    })
    const { inputs, sending } = state
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {
            to: inputs[0].value,
            from:inputs[1].value
        }
        data.forEach((v, k) => obj[k] = v)
        setState({ ...state, sending: true })
        Apis({
            action: 'sendEmail',
            module: 'Users',
            ...obj
        }).then((resp) => {
            EventsEmit('app', {
                info: {
                    message: 'Email has been sent.',
                    status: 'success'
                }
            })
            navigate(-1)
        }).catch(e => {
            EventsEmit('app', {
                info: {
                    message: 'Email not sent.',
                    status: 'error'
                }
            })
            setState({
                ...state,
                sending: false
            })
        })
    }

    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            <PageTitle backBtn title={"New Email"} />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={formRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, required, value, field, error, options }, i: number) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={['Message', 'Subject'].includes(label) ? 12 : 6}
                                    key={field}
                                >
                                    <TextField
                                        autoComplete="given-name"
                                        name={field}
                                        disabled={['to', 'from'].includes(field)}
                                        required={required}
                                        defaultValue={value}
                                        fullWidth
                                        label={label}
                                        type={type || 'text'}
                                        autoFocus
                                        error={Boolean(error)}
                                        helperText={error}
                                        maxRows={10}
                                        minRows={5}
                                        multiline={label === 'Message'}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={sending}
                    >
                        Send
                    </LoadingButton>
                </Box>
            </Container>
        </Box>
    )
}

export default Connect(mapStateToProps)(NewEmail)