const columns = [
    {
        label: 'Date',
        name: 'payment',
        field:'dateOfPayment',
        isDate: true
    },
    
    {
        label: 'Reference Number',
        name: 'payment',
        field:'referenceNumber'
    },
    {
        label: 'Tenant',
        field: 'name',
        name: 'tenant'
    },
    {
        label: 'Amount (Kes)',
        name: 'payment',
        field: 'amount',
        isNumber: true
    },
    {
        label: 'Unit',
        field: 'name',
        name: 'unit'
    },
   
    {
        label: 'Unit Label / House No.',
        field: 'unitLabel',
        name: 'unitLabel'
    },

    {
        label: 'Invoice',
        name: 'invoice',
        field: 'title'
    },
    {
        label: 'Title',
        name: 'title'
    },
    {
        label: 'Description',
        name: 'description'
    },
    
    {
        label: 'Payment Method',
        name: 'payment',
        field: 'method'
    },
    {
        label: 'Status',
        name: 'status'
    }
    // {
    //     label: 'Action',
    //     name: 'action'
    // }
]
export default columns