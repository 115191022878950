import  Properties  from '../Properties';
import NewAppartment  from '../Properties/new'
import Dashboard  from '../Dashboard'
import Units  from '../Units'
import NewUnit  from '../Units/new'
import Tenants  from '../Tenants'
import NewTenant  from '../Tenants/new'
import Utilities  from '../Utilities'
import Account  from '../Account'
import NewUtitlity  from '../Utilities/new'
import Maintenances  from '../Maintenances'
import NewMaintenance  from '../Maintenances/new'
import UnitLabels  from '../UnitLabels'
import NewUnitLabel  from '../UnitLabels/new'
import Invoices  from '../Invoices'
import NewInvoice  from '../Invoices/new'
import Payments  from '../Payments'
import NewPayment  from '../Payments/new'
import Tenant  from '../Tenants/Tenant'
import NewEmail  from '../Tenants/Email/new'
import Arrears  from '../Arrears'
import NewArrear  from '../Arrears/new'
import Deductions  from '../Deductions'
import Expenditures  from '../Expenditures';
import NewUserRole from '../Account/Users/new';
import LoggedIn from '../Auth/loggedIn';
import Plans from '../Plans';
import PayForPlan from '../Plans/payForPlan';


const Screens = [
    { path: '/', element: <Properties /> },
    { path: '/auth', element: <LoggedIn /> },
    { path: '/plans', element: <Plans /> },
    { path: '/pay_for_plan', element: <PayForPlan /> },
    { path: '/properties', element: <Properties /> },
    { path: '/new_property', element: <NewAppartment /> },
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/units', element: <Units /> },
    { path: '/new_unit', element: <NewUnit /> },
    { path: '/tenants', element: <Tenants /> },
    { path: '/new_tenant', element: <NewTenant /> },
    { path: '/utilities', element: <Utilities /> },
    { path: '/new_utility', element: <NewUtitlity /> },
    { path: '/account_settings', element: <Account/> },
    { path: '/maintenances', element: <Maintenances /> },
    { path: '/new_maintenance', element: <NewMaintenance /> },
    { path: '/units_labels', element: <UnitLabels /> },
    { path: '/new_unitLabel', element: <NewUnitLabel /> },
    { path: '/invoices', element: <Invoices /> },
    { path: '/new_invoice', element: <NewInvoice /> },
    { path: '/payments', element: <Payments /> },
    { path: '/new_payment', element: <NewPayment /> },
    { path: '/tenant', element: <Tenant /> },
    { path: '/new_email', element: <NewEmail /> },
    { path: '/arrears', element: <Arrears /> },
    { path: '/new_arrear', element: <NewArrear /> },
    { path: '/deductions', element: <Deductions /> },
    { path:  '/expenditures', element: <Expenditures/>},
    { path:  '/NewUserRole', element: <NewUserRole/>}


]
const routes: any[] = Screens

export default routes;