import Copyright from '../Common/Copyright';
import PageTitle from '../Common/PageTitle'
import { Box, Container, List, ListItem, Typography } from '@mui/material';

interface Props {

}

const Policies = (props: Props) => {
    const privacyAndPolicies = [
        {
            title: 'Data Collection',
            description: 'We collect personal information such as names, contact details, and payment information to provide our property management services effectively. This information is necessary for creating and managing user accounts, facilitating property transactions, and providing customer support.',
        },
        {
            title: 'Data Usage',
            description: 'Personal information collected will be used solely for purposes outlined in our privacy policy. This includes facilitating property transactions, communicating with users regarding their accounts or services, and improving our platform\'s functionality and user experience.',
        },
        {
            title: 'Data Protection',
            description: 'We take the security of user data seriously and employ industry-standard security measures to safeguard it against unauthorized access, alteration, disclosure, or destruction. This includes encryption of sensitive data, regular security audits, and access controls to limit data access to authorized personnel only.',
        },
        {
            title: 'Third-party Sharing',
            description: 'We may share user information with trusted third-party service providers only when necessary to fulfill the purposes outlined in our privacy policy. This includes service providers such as payment processors, email service providers, and hosting providers. We ensure that these third parties adhere to strict data protection standards and only share information essential for carrying out their services.',
        },
        {
            title: 'Data Retention',
            description: 'User data will be retained only for as long as necessary to fulfill the purposes outlined in our privacy policy or as required by law. This includes retaining transactional data for accounting and legal purposes and anonymizing or deleting personal information when it is no longer needed.',
        },
        {
            title: 'User Consent',
            description: 'By using our property management system, users consent to the collection, processing, and sharing of their personal information in accordance with our privacy policy. Users have the right to withdraw their consent at any time by contacting us or adjusting their account settings, although this may affect their ability to use certain features or services.',
        },
        {
            title: 'Cookie Policy',
            description: 'We use cookies and similar technologies to enhance user experience, analyze usage patterns, and personalize content. Cookies are small text files stored on users\' devices that track their interactions with our platform. Users can manage cookie preferences through their browser settings or opt-out of non-essential cookies.',
        },
        {
            title: 'Opt-out Options',
            description: 'Users have the right to opt-out of certain data processing activities, such as receiving marketing communications or sharing their information with third parties for advertising purposes. Users can exercise this right by contacting our support team or adjusting their account settings. Opting out may limit users\' access to certain features or services.',
        },
        {
            title: 'Data Access and Correction',
            description: 'Users have the right to request access to their personal information stored in our systems and request corrections or updates to ensure its accuracy. Users can do this by contacting our support team or accessing their account settings. We will respond to such requests promptly and provide users with the means to review, update, or delete their information as necessary.',
        },
        {
            title: 'Policy Updates',
            description: 'We reserve the right to update our privacy policy periodically to reflect changes in legal requirements or our business practices. Users will be notified of any significant updates via email or through our platform. Continued use of our property management system after such updates constitutes acceptance of the revised privacy policy.',
        },
        {
            title: 'User Communication',
            description: 'We may communicate with users via email, in-app notifications, or other channels regarding important updates, announcements, or changes to our services. Users may opt-out of non-essential communications but should stay informed about critical information related to their accounts or the platform.',
        },
        {
            title: 'Geolocation Data',
            description: 'We may collect and process geolocation data from users\' devices to provide location-based services or features. Users can control location-sharing preferences through their device settings or within the app. Geolocation data will only be used for purposes outlined in our privacy policy.',
        },
        {
            title: 'Children\'s Privacy',
            description: 'Our property management system is not intended for use by children under the age of 13. We do not knowingly collect personal information from children without parental consent. If we discover that we have inadvertently collected information from a child, we will promptly delete it from our systems.',
        },
        {
            title: 'User Feedback',
            description: 'We encourage users to provide feedback, suggestions, or bug reports to help improve our property management system. Users should submit feedback through designated channels or contact our support team. We may use user feedback to enhance features, address issues, or make improvements to the platform.',
        },
        {
            title: 'Data Portability',
            description: 'Users have the right to request a copy of their personal information in a portable, machine-readable format for transfer to another service or platform. Users can make such requests by contacting our support team. We will provide the requested data in a timely manner, subject to verification of identity and any applicable legal requirements.',
        },
        {
            title: 'Advertising',
            description: 'We may display advertisements on our property management system from third-party advertisers or ad networks. These advertisements may be targeted based on user interests, demographics, or behavior. Users may have options to control ad personalization settings through their account preferences or device settings.',
        },
        {
            title: 'User Conduct',
            description: 'Users are expected to conduct themselves responsibly and respectfully when using our property management system. This includes refraining from engaging in any illegal, harmful, or abusive behavior, such as harassment, spamming, or unauthorized access. Violations of our terms of service or community guidelines may result in account suspension or termination.',
        },
        {
            title: 'Data Deletion',
            description: 'Users have the right to request deletion of their personal information from our systems, subject to any legal obligations or legitimate interests. Users can make such requests by contacting our support team or accessing their account settings. We will permanently delete or anonymize the requested data within a reasonable timeframe.',
        },
        {
            title: 'User Support',
            description: 'We provide user support to assist with account-related inquiries, technical issues, or general questions about our property management system. Users can contact our support team via email, live chat, or through our help center. We strive to respond to user inquiries promptly and resolve issues in a timely manner.',
        }
    ];

    return (
        <Container>
            <PageTitle title={'Privacy Policies'} />
            <List sx={{ mt: 3 }}>
                {privacyAndPolicies.map((policy, index) => (
                    <ListItem key={index} >
                        <Typography variant="body1">
                            <strong>{policy.title}:</strong> {policy.description}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

export default Policies