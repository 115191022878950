import { Box } from "@mui/material"
import PageTitle from "../Common/PageTitle"



const Plans = () => {
    return (
        <Box sx={{width:'100%', pr:5}}>
            <PageTitle title="Packages" />
        </Box>
    )
}

export default Plans