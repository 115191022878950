import PageTitle from '../Common/PageTitle'
import { Autocomplete, Box, CircularProgress, Container, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { InputProps } from '../../types'
import { Apis } from '../../helpers'
import { Connect, mapStateToProps } from '../../Redux'
import { PropertyProps, UnitLabelProps, UnitsProps } from '../../types/properties'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { MenuProps } from '../UnitLabels/new'
import { EventsEmit } from '../../configs/events'
interface Props {
    selectedProperty?: PropertyProps
}
interface StateProps {
    inputs: InputProps[]
    adding: boolean
    units: UnitsProps[]
    loadingUnits: boolean
    selectedLabel: UnitLabelProps | null
    selectedUnit: {
        name: string,
        _id: string,
        labels: UnitLabelProps[]
    } | null
}
const NewInvoice: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const { record } = location?.state || {}
    const fomrRef = useRef(null)



    const initialInputs: InputProps[] = [
        { label: 'Title', value: '', field: 'title', required: true },
        { label: 'Description', value: '', field: 'description', required: true },
        { label: 'Unit', value: '', field: 'unit', required: true },
        { label: 'Unit Label / House No.', value: '', field: 'unitLabel', required: true },
        { label: 'Amount', value: '', type: 'number', field: 'amount', required: true },
        { label: 'Last Date Of Payment', value: '', type: 'date', field: 'lastDateOfPayment', required: true }
    ]
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        adding: false,
        units: [],
        loadingUnits: false,
        selectedUnit: null,
        selectedLabel: null
    })
    const navigate = useNavigate()

    const { inputs, adding, units, loadingUnits, selectedUnit, selectedLabel } = state
    const InvoiceOptions = ['Rent', 'Water Bill', 'Electricity Bill', 'Garbage Collection Bill', 'Internet']
   
    //functions
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        setState({ ...state, adding: true })
        Apis({
            module: 'Invoices',
            action: 'addUpdateInvoice',
            ...obj,
            _id: record?._id,
            propertId: selectedProperty?._id,
            unitLabelId: selectedLabel?._id,
            tenantId: selectedLabel?.userId,
            unitId: selectedUnit?._id,
        }).then(({ message }: any) => {
            (fomrRef.current as any).reset()
            setState({ ...state, adding: false })
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                },
            })
            if (record?._id)
                navigate(-1)
        }).catch((e) => {
            setState({ ...state, adding: false })
        })

    };
    const getUnitsWithLabels = () => {
        Apis({
            module: 'Properties',
            action: 'getUnitsWithLabels',
            _id: selectedProperty?._id,
            occupied: true
        }).then((units: any) => {
            if (record) {
                const SelectedUnit = units.find((q: UnitsProps) => q._id === record.unitId)
                setState({
                    ...state,
                    units,
                    selectedUnit: SelectedUnit,
                    selectedLabel: SelectedUnit.labels.find((q: UnitLabelProps) => q._id === record.tenantId),
                    loadingUnits: false
                })
            }
            else
                setState({
                    ...state,
                    units,
                    loadingUnits: false
                })
        }).catch((e) => {
            console.log(e)
            setState({
                ...state,
                loadingUnits: false
            })
        })
    }
    useEffect(() => {
        getUnitsWithLabels()
        return () => {

        }
    }, [])
    return (
        <Fragment  >
            <PageTitle
                title={`${record?._id ? 'Update' : 'Create'} Invoice`}
                backBtn
            />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={fomrRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, required, value, field, error, options }, i: number) => (

                                <Grid
                                    item
                                    xs={12}
                                    sm={['Description'].includes(label) ? 12 : 6}
                                    key={field}
                                >
                                    {
                                        field === 'title' ?
                                            <Autocomplete
                                                freeSolo
                                                id="free-solo-2-demo"
                                                // defaultValue={unit && unit[field] || ''}
                                                disableClearable
                                                options={InvoiceOptions.sort((a, b) => a > b ? 1 : a < b ? -1 : 0)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={label + ' '}
                                                        name={field}
                                                        // onFocus={() =>  changeInput()}
                                                        required
                                                        error={Boolean(error)}
                                                        helperText={error}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search'
                                                        }}
                                                    />
                                                )}
                                            /> :
                                            type === 'date' ?
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        name={field}
                                                        label={label}
                                                        format={'DD/MM/YYYY'}
                                                    //enter max date
                                                    />
                                                </LocalizationProvider> :
                                                ['unit', 'unitLabel'].includes(field) ?
                                                    <Fragment>
                                                        <InputLabel sx={{ mt: -1 }} >{label}</InputLabel>
                                                        <Select
                                                            labelId="demo-multiple-name-label"
                                                            defaultValue={
                                                                field === 'unit' ?
                                                                    record?.unit :
                                                                    record?.unitLabel
                                                            }
                                                            onChange={(event) => {
                                                                const stateField = field === 'unit' ? 'selectedUnit' : 'selectedLabel'
                                                                const newValue = field === 'unit' ? (units.find(q => q.name === event.target.value) as UnitsProps) : (selectedUnit?.labels.find(q => q.unitLabel === event.target.value) as UnitLabelProps)
                                                                setState({
                                                                    ...state,
                                                                    [stateField]: newValue
                                                                })
                                                            }}
                                                            input={<OutlinedInput
                                                                fullWidth
                                                                label={label}
                                                                endAdornment={
                                                                    loadingUnits ?
                                                                        <InputAdornment position="end">
                                                                            <CircularProgress size={25} />
                                                                        </InputAdornment> :
                                                                        <Fragment />
                                                                }
                                                            />}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {((field === 'unit' ? units : (selectedUnit?.labels)?.sort((a: any, b: any) => a.unitLabel > b.unitLabel ? 1 : a.unitLabel < b.unitLabel ? -1 : 0)) || []).map(({ name, unitLabel }: any) => {
                                                                const val = field === 'unit' ? name : unitLabel
                                                                return (
                                                                    <MenuItem
                                                                        key={val}
                                                                        value={val}
                                                                    >
                                                                        {val}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Fragment> :
                                                    <TextField
                                                        autoComplete="given-name"
                                                        name={field}
                                                        required={required}
                                                        defaultValue={record && record[field]}
                                                        fullWidth
                                                        label={label}
                                                        type={type || 'text'}
                                                        autoFocus
                                                        error={Boolean(error)}
                                                        helperText={error}
                                                        maxRows={10}
                                                        minRows={5}
                                                        multiline={label === 'Description'}
                                                    />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={adding}
                    >
                        {`${record?._id ? 'Update' : 'Create'} Invoice`}
                    </LoadingButton>
                </Box>
            </Container>
        </Fragment>
    )
}

export default Connect(mapStateToProps)(NewInvoice)
