const columns = [
    {
        label: 'Unit',
        name: 'unit'
    },
    {
        label: 'Label',
        name: 'unitLabel'
    },
    {
        label: 'Vacant',
        name: 'vacant'
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns