import { Link, Typography } from '@mui/material'
import React from 'react'
import { appName } from '../../../configs'

const Copyright = (props: any) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href= "/">
                {appName}
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    )
}

export default Copyright