import PageTitle from '../Common/PageTitle'
import { Box, Button, CircularProgress, Container, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { InputProps } from '../../types'
import { theme } from '../../theme'
import { PropertyProps, UnitLabelProps, UnitsProps } from '../../types/properties'
import { Connect, mapDispatchToProps, mapStateToProps } from '../../Redux'
import { Apis, uploadToDigitalOceans } from '../../helpers'
import { EventsEmit } from '../../configs/events'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab';
import { MenuProps } from '../UnitLabels/new'

interface Props {
    selectedProperty?: PropertyProps
}
interface ImageProps {
    label: string
    base64: null | { data: string, ext: string }
    field: string
    image: string | null
}
interface StateProps {
    inputs: InputProps[]
    images: ImageProps[]
    units: UnitsProps[]
    adding: boolean
    loadingUnits: boolean
    selectedLabel: UnitLabelProps | null
    selectedUnit: {
        name: string,
        _id: string,
        labels: UnitLabelProps[]
    } | null
}
const Newrecord: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const { record, newTenant } = location?.state || {}
    const fomrRef = useRef(null)


    const initialInputs: InputProps[] = [
        { label: 'Name', value: '', field: 'name' },
        { label: 'Phone', value: '', field: 'phone' },
        { label: 'Email', value: '', type: 'email', field: 'email' },
        { label: 'Identification Number', value: '', field: 'Idnumber' },
        { label: 'Unit', value: '', field: 'unit' },
        { label: 'Unit Label / House No.', value: '', field: 'unitLabel' },
    ]
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        images: [
            { label: 'Front Image', field: 'frontImage', base64: null, image: null },
            { label: 'Back Image', field: 'backImage', base64: null, image: null }
        ],
        units: [],
        loadingUnits: true,
        adding: false,
        selectedUnit: null,
        selectedLabel: null
    })
    const { inputs, images, units, loadingUnits, adding, selectedUnit, selectedLabel } = state
    const navigate = useNavigate()
    const refs = {
        frontImage: useRef(null) as any,
        backImage: useRef(null) as any,
    }
    //functions
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        const newData = {
            module: 'Properties',
            action: 'addUpdateTenant',
            propertId: selectedProperty?._id,
            unitLabelId: selectedLabel?._id || record.labelId,
            ...obj,
            unitId: selectedUnit?._id || record?.unit?._id,
            oldUnitId: !newTenant? record?.unit?._id:undefined,
            oldUnitLabelId: !newTenant ?record?.labelId:undefined,
            isNewTenant: !Boolean(record?._id)
        }
        setState({
            ...state,
            adding: true
        })
        let newImages: any;
        let base64Images: any = [];

        images.filter(q => Boolean(q.base64)).forEach(q => base64Images.push(q.base64))
        if (base64Images.length) {
            newImages = await uploadToDigitalOceans({
                base64Images,
                folder: "user_documents",
            })
            newData.frontImage = newImages[0]?.image
            newData.backImage = newImages[1]?.image
        }
        Apis(newData).then(({ message }: any) => {
            //reset inputs
            //show toast message
            (fomrRef.current as any).reset()
            setState({ ...state, adding: false })
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                },
            })
            navigate(-1)
        }).catch((e) => {
            setState({ ...state, adding: false })
        })

    };
    const getUnitsWithLabels = () => {
        Apis({
            module: 'Properties',
            action: 'getUnitsWithLabels',
            _id: selectedProperty?._id,
            tenantId: record?._id,
            labelId: record?.labelId,
            vacant: true
        }).then((units: any) => {
            if (record) {
                const SelectedUnit = units.find((q: UnitsProps) => q._id === record.unit._id)
                setState({
                    ...state,
                    units,
                    selectedUnit: SelectedUnit,
                    selectedLabel: SelectedUnit.labels.find((q: UnitLabelProps) => q._id === record.labelId),
                    loadingUnits: false
                })
            }
            else
                setState({
                    ...state,
                    units,
                    loadingUnits: false
                })
        }).catch(() => {
            setState({
                ...state,
                loadingUnits: false
            })
        })
    }
    useEffect(() => {
        getUnitsWithLabels()
        return () => {

        }
    }, [])
    return (
        <Fragment  >
            <PageTitle
                title={`${record?._id ? 'Update' : 'Add'} record`}
                backBtn
            />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={fomrRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, field, error }, i: number) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={label === 'Description' ? 12 : 6}
                                    key={field}
                                >
                                    {
                                        ['unit', 'unitLabel'].includes(field) ?
                                            <Fragment>
                                                <InputLabel sx={{ mt: -1 }} >{label}</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    defaultValue={
                                                        field === 'unit' ?
                                                            record?.unit?.name :
                                                            record?.unitLabel
                                                    }
                                                    onChange={(event) => {
                                                        const stateField = field === 'unit' ? 'selectedUnit' : 'selectedLabel'
                                                        const newValue = field === 'unit' ? units.find(q => q.name === event.target.value) as UnitsProps : selectedUnit?.labels.find(q => q.unitLabel === event.target.value) as UnitLabelProps
                                                        setState({
                                                            ...state,
                                                            [stateField]: newValue
                                                        })
                                                    }}
                                                    input={<OutlinedInput
                                                        fullWidth
                                                        label={label}
                                                        endAdornment={
                                                            loadingUnits ?
                                                                <InputAdornment position="end">
                                                                    <CircularProgress size={25} />
                                                                </InputAdornment> :
                                                                <Fragment />
                                                        }
                                                    />}
                                                    MenuProps={MenuProps}
                                                >
                                                    {((field === 'unit' ? units : selectedUnit?.labels) || []).map(({ name, unitLabel }: any) => {
                                                        const val = field === 'unit' ? name : unitLabel
                                                        return (
                                                            <MenuItem
                                                                key={val}
                                                                value={val}
                                                            >
                                                                {val}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </Fragment>
                                            :
                                            <TextField
                                                autoComplete="given-name"
                                                name={field}
                                                required={label !== 'Description'}
                                                fullWidth
                                                defaultValue={record && record[field]}
                                                label={label}
                                                type={type || 'text'}
                                                error={Boolean(error)}
                                                helperText={error}
                                                maxRows={10}
                                                minRows={5}
                                                multiline={label === 'Description'}
                                            />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Typography sx={{ mt: 5, fontWeight: 'bold' }}>Identification Document</Typography>
                    {
                        images.map(({ label, field, image, base64 }, i) => (
                            <Box key={label} sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <Button sx={{
                                    height: 300,
                                    width: 500,
                                    borderRadius: 5,
                                    border: '1px dashed ' + theme.lightTheme.palette.primary.main,
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textTransform: 'none'
                                }}
                                    onClick={() => {
                                        if ((refs as any)[field].current)
                                            (refs as any)[field].current.click();
                                    }}
                                >
                                    {
                                        image || base64 ?
                                            <img
                                                src={
                                                    base64
                                                        ? `data:image/${base64.ext};base64,${base64.data}`
                                                        : (image as string)
                                                }
                                                alt={label}
                                                style={{
                                                    height: image || base64 ? 290 : 290,
                                                    width: image || base64 ? 430 : 490,
                                                    borderRadius: 20,
                                                    objectFit: 'fill'
                                                }} /> :
                                            <Typography sx={{
                                                fontSize: 18,
                                                color: theme.lightTheme.palette.primary.main
                                            }}>Upload Image</Typography>
                                    }
                                </Button>
                                {
                                    image || base64 ?
                                        <Button
                                            sx={{
                                                textTransform: 'none',
                                                ml: 5,
                                                height: 40
                                            }}
                                            variant={'outlined'}
                                            onClick={() => {
                                                if ((refs as any)[field].current)
                                                    (refs as any)[field].current.click();
                                            }}
                                        >Update</Button> :
                                        <Fragment />
                                }
                                <input
                                    placeholder="just a placeholder"
                                    ref={(refs as any)[field]}
                                    style={{
                                        height: 0,
                                        width: 0,
                                        opacity: 0,
                                    }}
                                    type={"file"}
                                    onChange={(event: any) => {
                                        const file = event.target.files[0];
                                        if (file) {
                                            const reader = new FileReader();

                                            reader.onloadend = () => {
                                                const base64Image = reader.result as string;
                                                const data = base64Image.split(",")[1];
                                                const ext = base64Image.split("image/")[1].split(";")[0];
                                                const Images = [...images]
                                                Images[i] = { ...Images[i], base64: { ext, data } }
                                                setState({ ...state, images: Images });
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                />
                            </Box>
                        ))
                    }
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={adding}
                    >
                        {`${record?._id ? 'Update' : 'Add'} Tenant`}
                    </LoadingButton>
                </Box>
            </Container>
        </Fragment>
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(Newrecord)
