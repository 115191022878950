import Auth from '../Auth'
import { FC, Fragment } from 'react'
import { Connect, mapStateToProps } from '../../Redux'
import { UserProps } from '../../types/user'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './routes'
import { Box, Link } from '@mui/material';
import MiniDrawer, { terms_urls } from '../Common/Drawer'
import Terms_conditions from '../Links/terms_conditions';
import Policies from '../Links/policies';
import Copyright from '../Common/Copyright';
import Contactus from '../Links/contactus';
import Faqs from '../Links/faqs';

const links = [
    {label:'Terms & Conditions', path:'terms_conditions', element:<Terms_conditions />},
    {label:'Privacy Policies', path:'privacy_policies',  element:<Policies />},
    {label:'Contact Us', path:'/contact_us',  element:<Contactus />},
    {label:'FAQs', path:'/faqs',  element:<Faqs />}
  ]

interface Props {
    User?: UserProps
    drawerOpen?: boolean
}
const Navigation: FC<Props> = ({
    User,
    drawerOpen
}) => {
    return (
        <Fragment>

        <Box
            sx={{
                height: '100vh',
                bgcolor: '#fff',
                pl: !User || terms_urls.includes(window.location.pathname) ? 0 : drawerOpen ? 40 : 10,
                pt: User || terms_urls.includes(window.location.pathname) ? 15 : 0
            }}
        >
            <Router>
                <MiniDrawer />
                <Routes>
                    {
                        links.map((link)=>(
                            <Route key={link.label} path={link.path} element={link.element} />
                        ))
                    }
                    {
                        User ?
                            routes.map(({ path, element }, i) => (
                                <Route key={i} path={path} element={element} />
                            )) :
                            <Fragment>
                                <Route path={'/'} element={<Auth />} />
                                <Route path={'/auth'} element={<Auth />} />
                            </Fragment>

                    }
                </Routes>
            </Router>
        </Box>
        <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                mt: 5,
                justifyContent: 'center',
                 pb:3
            }}>
                {
                    links.map(({ label, path }) =>
                        <Link 
                        key={label} 
                        sx={{
                            textAlign: 'center',
                            fontSize: 14,
                             mr:2
                        }} href={path}>{label}</Link>
                    )
                }
            </Box>
            <Copyright />
            <br/>
        </Fragment>


    )
}

export default Connect(mapStateToProps)(Navigation)
