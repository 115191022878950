const columns = [
   {
    label:"Name",
    name:'name'

   },
   {
    label:"Role",
    name:"role"
   },
   {
    label:"Action",
    name:"action"
   }
]
export default columns