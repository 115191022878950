import { Send } from '@mui/icons-material'
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { theme } from '../../theme'
import { Connect, mapStateToProps } from '../../Redux'
import { Apis } from '../../helpers'
import { LoadingButton } from '@mui/lab'
import { EventsEmit } from '../../configs/events'
import { useNavigate } from 'react-router-dom'
import PageTitle from '../Common/PageTitle'

interface StateProps {
    submitting: boolean
}

const Contactus = () => {
    const [state, setState] = useState<StateProps>({
        submitting: false
    })
    const navigate = useNavigate()
    const { submitting } = state
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        setState({ ...state, submitting: true })
        Apis({
            module: 'Users',
            action: 'addFAQ',
            ...formJson
        }).then(({ message }: any) => {
            setState({ ...state, submitting: false })
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                }
            })
            setTimeout(() => {
            navigate(-1)
            }, 2000);

        }).catch((err) => {
            setState({ ...state, submitting: false })

        })
    }
    return (
        <Container
           
        >
            <PageTitle title='Contact Us' />
            <Box
            onSubmit={onSubmit}
            component={'form'} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100vh'
            }}>

                <Typography component={'h1'} sx={{ fontSize: 50, mb: 10, color: theme.lightTheme.palette.primary.main }}>Get In Touch With Us</Typography>
                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Grid item xs={12} md={7}>
                        <TextField minRows={2} maxRows={2} required multiline name="subject" fullWidth placeholder='How Can...' label={'Subject'} />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <TextField multiline minRows={5} required maxRows={6} name="message" fullWidth placeholder='Please write in brief...' label={'Your Message'} />
                    </Grid>
                </Grid>
                <LoadingButton type='submit' loading={submitting} sx={{ mt: 5, pt: 1, pb: 1 }} variant={'contained'}>Send Message <Send sx={{ ml: 2, }} /></LoadingButton>
            </Box>

        </Container>
    )
}

export default Connect(mapStateToProps)(Contactus)
