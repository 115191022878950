
export const url = '',
    appName = 'SpotStay',


api_public_key = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxugmKhK8+QTi+qtsiUtH
fK+HTZQ5PDCzFmLhV8ArP2JohedXIE3+ooOZ2yUVXCn2DXNFYm2QHgZ3Va+31QFp
VjPzh6YnaRaxrMkD36Gy+hlwAIl9wZuLbHLW0x1N4Cb4/59AW1j5VKinQAdC5vR7
6gm+35KkViWvrR6UuC5O89Zc+ax/H6RLDapo31U/b5zoP0FCHpK8v5iivpren2qL
c3SnERTtpL4jiImDosg114eL2G8+vAGLp0d9Br4CX8gpzd/wPAylS56SH92W+f0G
nuE0pb94cEjNA32v2pEIqmMD6jU0dx2VUXVPBctOKsZK876LmGQEVFygQeZ7/OUP
U5F/CP9bmQWryBs/TjBDwOrLOLbUpEji01OG6OkkM9jhdIDC4RoLk6bbkFhwcLPQ
UFQ/yS8JC3wtNP3McZTdziBhFgedDk5fuHwuhRW7b+U9SQDnt3qs3DQRsvTv/uCR
owGGU/tEYP58pU4PHg6SfapJNxOgSJzpeAQ7thc6NXdaxcuyLrOpc4bRVnGb7scP
HbkWabdhVB0cQa6D0ggbg6OH4gAd0VnKd3MM3OVvpb+gReousextMUiQM4+WzBOg
CLaqPkEn4vN7zx3mtzvEURO9xuYe50sBj74m50EI9MaSkUdS5+cXLkrViO2ND0E6
DFHAgkk5W6BCObJ5M/u8TxUCAwEAAQ==
-----END PUBLIC KEY-----`,
    prod = true,
    env: 'production' | 'development' = prod ? 'production' : 'development',
    TABLE_HEIGHT = '80vh'
