import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, IconButton, TablePagination, TextField } from '@mui/material';
import { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import PageTitle from '../Common/PageTitle';
import { Apis } from '../../helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Cancel, Search } from '@mui/icons-material';
import NoData from '../Common/NoData';
import _ from 'lodash';

interface StateProps {
    faqs: {
        _id: string
        subject: string;
        answer: string;
    }[],
    loading: boolean
    searchVal: string
    searching: boolean
    pageSize: number
    page: number
    count: number
    selectedAccordionIndex: null | number

}
const Faqs = () => {
    const [state, setState] = useState<StateProps>({
        faqs: [],
        loading: true,
        searching: false,
        searchVal: '',
        page: 0,
        pageSize: 10,
        count: 0,
        selectedAccordionIndex: null
    })
    const { faqs, selectedAccordionIndex, searchVal, loading, page, pageSize, count } = state;
    const inputRef: any = useRef(null)
    const searchData = (searchVal: string) => fetchFAQs({
        searchVal,
        processVariable: 'searching',
        newPage: 0
    })
    const startSearch = useCallback(_.debounce(searchData, 1000), [])
    const fetchFAQs = ({
        searchVal,
        newPage,
        newPageSize,
        processVariable
    }: any) => {
        if (processVariable)
            setState({ ...state, [processVariable]: true })
        const NewPage = newPage > -1 ? newPage : state.page
        const NewPageSize = newPageSize || state.pageSize
        const afterLoad = {
            loading: false,
            searching: false,
            refreshing: false,
            page: NewPage,
            pageSize: NewPageSize,
            searchVal
        }
        Apis({
            module: 'Users',
            action: 'getFAQs',
            searchVal,
            page: NewPage,
            pageSize: NewPageSize
        }).then((resp: any) => {
            const { faqs, count } = resp
            setState({
                ...state,
                faqs,
                count:count||state.count,
                ...afterLoad
            })
        }).catch(() => {
            setState({
                ...state,
                ...afterLoad
            })
        })
    }
    const onValueChange = (newValue: string) => {
        startSearch(newValue)
    }
    useEffect(() => {
        fetchFAQs({ processVariable: 'loading' })
    }, []);



    return (
        <Container sx={{ height: '100%' }}>
                <PageTitle title='FAQs' />
                <br />
                <Grid container spacing={2} sx={{
                    display: 'flex',
                    // justifyContent: 'center'
                }}>
                    <Grid item xs={12} md={8}>
                        <TextField
                            variant={'standard'}
                            fullWidth
                            // value={searchVal}
                            inputRef={inputRef}
                            placeholder='Search...'
                            onChange={(c) => onValueChange(c.target.value)}
                            InputProps={{
                                endAdornment:
                                    <Fragment>
                                        {
                                            searchVal &&
                                            <IconButton
                                                onClick={() => {
                                                    if (inputRef.current) {
                                                        inputRef.current.value = '';
                                                        onValueChange('')
                                                    }
                                                }}
                                                sx={{

                                                }}
                                            >
                                                <Cancel sx={{ color: '#CBCBCB', fontSize: 20 }} />
                                            </IconButton>
                                        }
                                        <Search />
                                    </Fragment>
                            }}
                        />
                    </Grid>
                </Grid>
                <br />
                {
                    !faqs?.length || loading ?
                        <NoData
                            message={`You don't have any unit.`}
                            createMessage={searchVal ? undefined : `Add New Unit`}
                            loading={loading}
                            searchVal={searchVal}
                        /> :
                        <Fragment >

                            {
                                faqs.map(({ _id, subject, answer }, i) => (
                                    <Accordion
                                        key={_id} expanded={selectedAccordionIndex === i}
                                        onChange={() => {
                                            setState({
                                                ...state,
                                                selectedAccordionIndex: selectedAccordionIndex === i ? null : i
                                            })
                                        }}
                                        sx={{ mt: 2, boxShadow: 'none' }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {subject}
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            {answer}
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                            <TablePagination
                                component="div"
                                count={count}
                                page={page}
                                onPageChange={(_, newPage: number) => {
                                    fetchFAQs({
                                        newPage,
                                        newPageSize: pageSize,
                                        processVariable: 'loading',
                                        searchVal
                                    })
                                }}
                                rowsPerPage={pageSize}
                                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                    const newValue = parseInt(event.target.value, 10)
                                    fetchFAQs({
                                        newPage: 0,
                                        newPageSize: newValue,
                                        processVariable: 'loading',
                                        searchVal
                                    })
                                }}
                                rowsPerPageOptions={[5, 10, 20, 25, 30, 50]}
                            />
                        </Fragment>
                }
        </Container>
    );
}

export default Faqs;
