import PageTitle from '../Common/PageTitle'
import { Box, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { InputProps } from '../../types'
import { Apis } from '../../helpers'
import { Connect, mapStateToProps } from '../../Redux'
import { PropertyProps, UnitLabelProps, UnitsProps } from '../../types/properties'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { MenuProps } from '../UnitLabels/new'
import { EventsEmit } from '../../configs/events'
interface Props {
    selectedProperty?: PropertyProps
}
interface StateProps {
    inputs: InputProps[]
    adding: boolean
    units: UnitsProps[]
    loadingUnits: boolean
    isFixed: boolean
    selectedLabel: UnitLabelProps | null
    selectedUnit: {
        name: string,
        _id: string,
        labels: UnitLabelProps[]
    } | null
}
const NewMaintenance: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const { record } = location?.state || {}
    const fomrRef = useRef(null)



    const initialInputs: InputProps[] = [
        { label: 'Title', value: '', field: 'title', required: true },
        { label: 'Description', value: '', field: 'description', required: true },
        { label: 'Unit', value: '', field: 'unit', required: true },
        { label: 'Unit Label / House No.', value: '', field: 'unitLabel', required: true },
        {
            label: 'Cost Of', value: '', type: 'radio', field: 'costOf', options: [
                { label: 'Tenant', value: 'Tenant' },
                { label: 'Landlord', value: 'Landlord' }
            ]
        },
        {
            label: 'Status', value: '', type: 'radio', field: 'status', options: [
                { label: 'Active', value: 'Active' },
                { label: 'Completed', value: 'Completed' }
            ]
        },
        { label: 'Date Fixed', value: '', type: 'date', field: 'dateFixed', required: true }
    ]
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        adding: false,
        units: [],
        isFixed: record?.isFixed || false,
        loadingUnits: false,
        selectedUnit: null,
        selectedLabel: null
    })
    const navigate = useNavigate()

    const { inputs, adding, isFixed, units, loadingUnits, selectedUnit, selectedLabel } = state
    //functions
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        setState({ ...state, adding: true })
        Apis({
            module: 'Maintenances',
            action: record ? 'updateMaintenance' : 'addMaintenance',
            ...obj,
            isFixed,
            _id: record?._id,
            propertId: selectedProperty?._id,
            tenantId: obj.costOf === 'Tenant' ? selectedLabel?.userId : undefined,
            unitLabelId: selectedLabel?._id,
            unitId: selectedUnit?._id,
            dateFixed: obj.dateFixed ? new Date(obj.dateFixed) : undefined
        }).then(({ message }: any) => {
            (fomrRef.current as any).reset()
            setState({ ...state, adding: false })
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                },
            })
            if (record?._id)
                navigate(-1)
        }).catch((e) => {
            setState({ ...state, adding: false })
        })

    };
    const getUnitsWithLabels = () => {
        Apis({
            module: 'Properties',
            action: 'getUnitsWithLabels',
            _id: selectedProperty?._id,
            all: true
        }).then((units: any) => {
            if (record) {
                const SelectedUnit = units.find((q: UnitsProps) => q._id === record.unitId)
                setState({
                    ...state,
                    units,
                    selectedUnit: SelectedUnit,
                    selectedLabel: SelectedUnit.labels.find((q: UnitLabelProps) => q._id === record.tenantId),
                    loadingUnits: false
                })
            }
            else
                setState({
                    ...state,
                    units,
                    loadingUnits: false
                })
        }).catch((e) => {
            console.log(e)
            setState({
                ...state,
                loadingUnits: false
            })
        })
    }
    useEffect(() => {
        getUnitsWithLabels()
        return () => {

        }
    }, [])
    return (
        <Fragment  >
            <PageTitle
                title={`${record?._id ? 'Update' : 'Add'} Maintenance`}
                backBtn
            />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={fomrRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, required, value, field, error, options }, i: number) => (
                                (!isFixed && ['dateFixed'].includes(field)) ?
                                    <Fragment key={field} /> :
                                    <Grid
                                        item
                                        xs={12}
                                        sm={['Description', 'Status', 'Cost Of'].includes(label) ? 12 : 6}
                                        key={field}
                                    >
                                        {
                                            type === 'radio' ?
                                                <FormControl>
                                                    <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue={!record ? (field === 'status' ? 'Active' : 'Landlord') : record[field]}
                                                        name={field}
                                                        onChange={(event) => {
                                                            if (field === 'status')
                                                                setState({
                                                                    ...state, isFixed: event.target.value === 'Completed'
                                                                })
                                                        }}
                                                    >
                                                        {
                                                            options?.map((option) => (
                                                                <FormControlLabel
                                                                    key={option.value}
                                                                    value={option.value}
                                                                    control={<Radio />} label={option.label} />
                                                            ))
                                                        }
                                                    </RadioGroup>
                                                </FormControl> :
                                                type === 'date' ?
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            name={field}
                                                            label={label}
                                                            format={'DD/MM/YYYY'}
                                                        //enter max date
                                                        />
                                                    </LocalizationProvider> :
                                                    ['unit', 'unitLabel'].includes(field) ?
                                                        <Fragment>
                                                            <InputLabel sx={{ mt: -1 }} >{label}</InputLabel>
                                                            <Select
                                                                labelId="demo-multiple-name-label"
                                                                defaultValue={
                                                                    field === 'unit' ?
                                                                        record?.unit :
                                                                        record?.unitLabel
                                                                }
                                                                onChange={(event) => {
                                                                    const stateField = field === 'unit' ? 'selectedUnit' : 'selectedLabel'
                                                                    const newValue = field === 'unit' ? (units.find(q => q.name === event.target.value) as UnitsProps) : (selectedUnit?.labels.find(q => q.unitLabel === event.target.value) as UnitLabelProps)
                                                                    setState({
                                                                        ...state,
                                                                        [stateField]: newValue
                                                                    })
                                                                }}
                                                                input={<OutlinedInput
                                                                    fullWidth
                                                                    label={label}
                                                                    endAdornment={
                                                                        loadingUnits ?
                                                                            <InputAdornment position="end">
                                                                                <CircularProgress size={25} />
                                                                            </InputAdornment> :
                                                                            <Fragment />
                                                                    }
                                                                />}
                                                                MenuProps={MenuProps}
                                                            >
                                                                {((field === 'unit' ? units : (selectedUnit?.labels)?.sort((a: any, b: any) => a.unitLabel > b.unitLabel ? 1 : a.unitLabel < b.unitLabel ? -1 : 0)) || []).map(({ name, unitLabel }: any) => {
                                                                    const val = field === 'unit' ? name : unitLabel
                                                                    return (
                                                                        <MenuItem
                                                                            key={val}
                                                                            value={val}
                                                                        >
                                                                            {val}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </Fragment> :
                                                        <TextField
                                                            autoComplete="given-name"
                                                            name={field}
                                                            required={required}
                                                            defaultValue={record && record[field]}
                                                            fullWidth
                                                            label={label}
                                                            type={type || 'text'}
                                                            autoFocus
                                                            error={Boolean(error)}
                                                            helperText={error}
                                                            maxRows={10}
                                                            minRows={5}
                                                            multiline={label === 'Description'}
                                                        />
                                        }
                                    </Grid>
                            ))
                        }
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={adding}
                    >
                        {`${record?._id ? 'Update' : 'Add'} Maintenance`}
                    </LoadingButton>
                </Box>
            </Container>
        </Fragment>
    )
}

export default Connect(mapStateToProps)(NewMaintenance)
