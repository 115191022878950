import PageTitle from "../Common/PageTitle";
import ToolBar from "../Common/Toolbar";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import columns from "./columns";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import MenuListPop from "../Common/menu";
import {
    DeleteOutlineOutlined,
    EditOutlined,
    PersonAddAltOutlined,
} from "@mui/icons-material";
import { Apis } from "../../helpers";
import { PropertyProps, UnitsProps } from "../../types/properties";
import NoData from "../Common/NoData";
import { useNavigate } from "react-router-dom";
import { Connect, mapStateToProps } from "../../Redux";
import DeleteModal from "../Common/deletePrompt";
import { TenantProps } from "../../types/tenants";
import { TABLE_HEIGHT } from "../../configs";
import _ from 'lodash';


interface StateProps {
    data: UnitsProps[]
    loading: boolean
    showDeleteModal: TenantProps | null
    refreshing: boolean
    searchVal: string
    searching: boolean
    pageSize: number
    page: number
    count: number
    distinctUnits: string[]
    filter: any

}
interface Props {
    selectedProperty?: PropertyProps;
}
const Utilities: FC<Props> = ({
    selectedProperty
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        showDeleteModal: null,
        loading: true,
        refreshing: false,
        searchVal: '',
        searching: false,
        page: 0,
        pageSize: 20,
        count: 0,
        distinctUnits: [],
        filter: null
    })
    const navigate = useNavigate()


    const { data, loading, showDeleteModal, filter, distinctUnits, searchVal, page, pageSize, count, refreshing } = state,
        searchUnitLabel = (searchVal: string) => getPropertyUnitLabels({
            searchVal,
            processVariable: 'searching',
            newPage: 0
        }),
        startSearch = useCallback(_.debounce(searchUnitLabel, 1000), [])
    const filterOptions = [
        { label: 'Unit', field: 'units', options: distinctUnits, type: 'multiSelect', selected: filter?.units || [] },
        { label: 'Vacant', field: 'vacant', type: 'radio', options: ['All', 'Yes', 'No'], selected: filter?.vacant || 'All' }
    ]
    const getPropertyUnitLabels = ({
        searchVal,
        newPage,
        newPageSize,
        processVariable,
        filter
    }: any) => {
        if (processVariable)
            setState({ ...state, [processVariable]: true })
        const NewPage = newPage > -1 ? newPage : state.page
        const NewPageSize = newPageSize || state.pageSize
        const afterLoad = {
            loading: false,
            searching: false,
            refreshing: false,
            page: NewPage,
            pageSize: NewPageSize,
            searchVal
        }
        Apis({
            module: 'Properties',
            action: 'getPropertyUnitLabels',
            _id: selectedProperty?._id,
            searchVal,
            page: NewPage,
            pageSize: NewPageSize,
            filter: filter || state.filter
        }).then((resp: any) => {
            const { count, distinctUnits, unitLabels } = resp
            setState({
                ...state,
                ...state,
                count: count || state.count,
                distinctUnits: distinctUnits || state.distinctUnits,
                ...afterLoad,
                filter,
                data: unitLabels,
            })
        }).catch(() => {
            setState({
                ...state,
                ...afterLoad,
                filter
            })
        })
    },
        onFilter = (filter: any) => {
            getPropertyUnitLabels({
                newPage: page,
                processVariable: 'loading',
                newPageSize: pageSize,
                filter
            })
        }
    useEffect(() => {
        getPropertyUnitLabels({
            newPage: page,
            processVariable: 'loading',
            newPageSize: pageSize
        })
        // eslint-disable-next-line 
    }, [])
    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            <PageTitle title={"Unit Labels (House No.s)"} />
            <ToolBar
                addTitle={'Add Unit Label'}
                addPage={'/new_unitLabel'}
                refreshing={refreshing}
                onRefresh={() => getPropertyUnitLabels({ processVariable: 'refreshing' })}
                onSearch={startSearch}
                filterOptions={filterOptions}
                onFilter={onFilter}
            />
            {
                !data?.length || loading ?
                    <NoData
                        message={`You don't have any unit labels.`}
                        createMessage={searchVal ? undefined : `Add Unit Label`}
                        onCreate={() => navigate('/new_unitLabel')}
                        loading={loading}
                        searchVal={searchVal}
                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: TABLE_HEIGHT }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map((datam, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={datam._id}>
                                                    {
                                                        columns.map(({ label, name }, x) => {
                                                            let value = name === 'unit' ? (datam as any).unit.name : name === 'unitLabel' ? datam.unitLabel : (datam.userId ? 'No' : 'Yes')
                                                            return (
                                                                <TableCell key={label + '' + datam.name} /*align={column.align}*/>
                                                                    {
                                                                        name === 'action' ?
                                                                            <MenuListPop menuList={
                                                                                [
                                                                                    { label: 'Edit', icon: <EditOutlined /> },
                                                                                    !datam.userId ? { label: 'Add Tenant', icon: <PersonAddAltOutlined /> } : null,
                                                                                    { label: 'Delete', icon: <DeleteOutlineOutlined /> }
                                                                                ].filter((q) => Boolean(q)) as any
                                                                            } onSelect={(option) => {
                                                                                if (option === 'Edit')
                                                                                    navigate('/new_unitLabel', { state: { record: datam } })
                                                                                else if (option === 'Delete')
                                                                                    setState({
                                                                                        ...state,
                                                                                        showDeleteModal: datam as any
                                                                                    })
                                                                                else
                                                                                    navigate('/new_tenant', {
                                                                                        state: {
                                                                                            record:
                                                                                            {
                                                                                                ...datam,
                                                                                                labelId: datam._id,
                                                                                                _id:undefined,
                                                                                            },
                                                                                            newTenant:true
                                                                                        }
                                                                                    })
                                                                            }} /> :
                                                                            value
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={count}
                            page={page}
                            onPageChange={(_, newPage: number) => {
                                getPropertyUnitLabels({
                                    newPage,
                                    newPageSize: pageSize,
                                    processVariable: 'loading',
                                    searchVal
                                })
                            }}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                const newValue = parseInt(event.target.value, 10)
                                getPropertyUnitLabels({
                                    newPage: 0,
                                    newPageSize: newValue,
                                    processVariable: 'loading',
                                    searchVal
                                })
                            }}
                            rowsPerPageOptions={[20, 25, 30, 50, 100]}
                        />
                    </Paper>
            }
            {
                showDeleteModal ?
                    <DeleteModal
                        item={showDeleteModal}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={(_, error) => {
                            if (error)
                                setState({
                                    ...state,
                                    showDeleteModal: null
                                })
                            else {
                                const itemIndex = data?.findIndex(q => q._id === showDeleteModal._id) as number
                                const Items = [...data as UnitsProps[]]
                                Items?.splice(itemIndex, 1)
                                setState({
                                    ...state,
                                    showDeleteModal: null,
                                    data: Items
                                })

                            }
                        }}
                        deleteField={'unitLabelId'}
                        page='Unit Label'
                        enPoint={'UnitLabel'}
                        module={'Properties'}
                        itemName={showDeleteModal.unitLabel}
                        unitLabelId={showDeleteModal._id}
                        unitId={showDeleteModal.unit._id}
                    /> :
                    <Fragment />

            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Utilities);
