const columns = [
    {
        label: 'Date added',
        name: 'dateAdded',
         isDate:true
    },
    {
        label: 'Name',
        name: 'title'
    },
    {
        label: 'Unit',
        name: 'unit'
    },

    {
        label: 'Unit Label / House No.',
        name: 'unitLabel'
    },
    {
        label: 'Description',
        name: 'description'
    },
    {
        label: 'Cost of',
        name: 'costOf'
    },
    {
        label: 'Status',
        name: 'status'
    },
    {
        label: 'Date Fixed',
        name: 'dateFixed'
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns