import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { FC, Fragment, useEffect, useState } from "react"
import Toolbar from "../Common/Toolbar"
import NoData from "../Common/NoData"
import MenuListPop from "../Common/menu"
import { useNavigate } from "react-router-dom"
import { Apis, numberWithCommas } from "../../helpers"
import { PropertyProps } from "../../types/properties"
import { Connect, mapStateToProps } from "../../Redux"
import moment from "moment"
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material"
import DeleteModal from '../Common/deletePrompt'
import { TenantProps } from "../../types/tenants"
import NewArrear from "./new"
import PageTitle from "../Common/PageTitle"
import columns from './columns'

interface Props {
    tenant?: TenantProps
    selectedProperty?: PropertyProps
    tablesColumnsFilters?: {
        arrears: string[]
    }
}
interface ArrearProps {
    amount: number,
    description: string,
    dateAdded: Date,
    dueDate: Date,
    _id: string
}
interface StateProps {
    data: ArrearProps[],
    loading: boolean
    showDeleteModal: null | ArrearProps
    refreshing: boolean
    addUpdateArrear: ArrearProps | true | null
}
const Arrears: FC<Props> = ({
    tenant,
    selectedProperty,
    tablesColumnsFilters
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        showDeleteModal: null,
        loading: true,
        refreshing: false,
        addUpdateArrear: null
    })
    const navigate = useNavigate()
    const { data, loading, showDeleteModal, refreshing, addUpdateArrear } = state
    const tenantColumns = ['dateAdded', 'dueDate', 'amount', 'description', 'notes', 'action']
    const DisplayColums = columns.filter(col => (tenant ? tenantColumns : tablesColumnsFilters?.arrears)?.includes(col.name))
    const menuList = [
        { label: 'Edit', icon: <EditOutlined /> },
        { label: 'Delete', icon: <DeleteOutlineOutlined /> }
    ]
    const getArrears = ({
        refreshing
    }: any) => {
        if (refreshing)
            setState({ ...state, refreshing: true })
        Apis({
            module: 'Payments',
            action: 'getArrears',
            propertId: selectedProperty?._id,
            tenantId: tenant?._id,
            unitLabelId:tenant?.labelId
        }).then((data: any) => {
            setState({
                ...state,
                data,
                loading: false,
                addUpdateArrear: null
            })
        }).catch(() => {
            setState({
                ...state,
                loading: false
            })
        })
    }

    useEffect(() => {
        getArrears({})
        // eslint-disable-next-line 
    }, [])
    return (
        <Box sx={{
            height: '100%',
            pr: 5
        }}>
            {!tenant ?
                <PageTitle title={"Arrears"} /> :
                <Fragment />
            }
            {!loading && data.length ?
                <Toolbar
                    columns={columns}
                    columnsFilter={'payments'}
                    addTitle={'Create Arrear'}
                    onAddClick={() => setState({ ...state, addUpdateArrear: true })}
                    refreshing={refreshing}
                    noSearch={Boolean(tenant)}
                    onRefresh={() => getArrears({ refreshing: true })}
                />
                : null}
            {
                !data?.length || loading ?
                    <NoData
                        createMessage={`Record Arrear`}
                        onCreate={() => setState({ ...state, addUpdateArrear: true })}
                        loading={loading}
                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: '70vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            DisplayColums.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map((datam, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={datam._id}>
                                                    {
                                                        DisplayColums.map(({ label, name, field, isDate, isNumber }, x) => {
                                                            let value = field && (datam as any)[name] ? (datam as any)[name][field] :(datam as any)[name] || 'None'
                                                            value = isDate ? moment(value).format('DD/MM/YYYY') : isNumber ? numberWithCommas(value) : value
                                                            return (
                                                                <TableCell key={label + '' + datam._id} /*align={column.align}*/>
                                                                    {
                                                                        name === 'action' ?
                                                                            <MenuListPop menuList={menuList} onSelect={option => {
                                                                                if (option === 'Edit')
                                                                                    navigate('/new_maintenance', { state: { record: datam } })
                                                                                else if (option === 'Delete')
                                                                                    setState({
                                                                                        ...state,
                                                                                        showDeleteModal: datam
                                                                                    })
                                                                            }} /> :
                                                                            value
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
            }

            {
                showDeleteModal ?
                    <DeleteModal
                        item={showDeleteModal}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={() => {
                            const itemIndex = data?.findIndex(q => q._id === showDeleteModal?._id) as number
                            const Items = [...data as ArrearProps[]]
                            Items?.splice(itemIndex, 1)
                            setState({
                                ...state,
                                showDeleteModal: null,
                                data: Items
                            })

                        }}
                        deleteField={'_id'}
                        page='Arrears'
                        module={'Payment'}
                        itemName={showDeleteModal.description}
                        arrearId={showDeleteModal._id}
                    /> :
                    <Fragment />

            }
            {addUpdateArrear ? <NewArrear
                close={() => setState({
                    ...state,
                    addUpdateArrear: null
                })}
                tenant={tenant}
                onCreateArrear={() => getArrears({
                    refreshing: true,
                    addUpdateArrear: null
                })}
            /> : <Fragment />}
        </Box>
    )
}

export default Connect(mapStateToProps)(Arrears)