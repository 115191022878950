import { createTheme, } from '@mui/material';

// Create a custom theme with the desired primary color
export default createTheme({
    palette: {
        primary: {
            main: '#5B7C03',  
            contrastText:'#EEF5DD'
        },
    },
});

