import PageTitle from "../Common/PageTitle"
import ToolBar from "../Common/Toolbar"
import { FC, Fragment, useEffect, useState } from "react"
import columns from "./columns"
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import MenuListPop from "../Common/menu"
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material"
import { Apis } from "../../helpers"
import { MaintenanceProps, PropertyProps } from "../../types/properties"
import NoData from "../Common/NoData"
import { useNavigate } from "react-router-dom"
import { Connect, mapStateToProps } from "../../Redux"
import moment from 'moment'
import DeleteModal from '../Common/deletePrompt'
import { TenantProps } from "../../types/tenants"


interface StateProps {
    data: MaintenanceProps[]
    loading: boolean
    showDeleteModal: null | MaintenanceProps
    refreshing: boolean
}
interface Props {
    tablesColumnsFilters?: {
        maintenances: string[]
    }
    selectedProperty?: PropertyProps
    tenant?: TenantProps
}
const Maintenances: FC<Props> = ({
    selectedProperty,
    tablesColumnsFilters,
    tenant
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        showDeleteModal: null,
        loading: true,
        refreshing: false
    })
    const navigate = useNavigate()

    const menuList = [
        { label: 'Edit', icon: <EditOutlined /> },
        { label: 'Delete', icon: <DeleteOutlineOutlined /> }
    ]
    const { data, loading, showDeleteModal, refreshing } = state
    const DisplayColums = columns.filter(col => tablesColumnsFilters?.maintenances.includes(col.name))

    const getPropertyMaintenances = ({
        refreshing
    }: any) => {
        if (refreshing)
            setState({ ...state, refreshing: true })
        Apis({
            module: 'Maintenances',
            action: 'getPropertyMaintenances',
            propertId: selectedProperty?._id,
            tenantId: tenant?._id,
            labelId: tenant?.labelId
        }).then((data: any) => {
            setState({ ...state, data, loading: false })
        }).catch(() => {
            setState({
                ...state,
                loading: false
            })
        })
    }
    useEffect(() => {
        getPropertyMaintenances({})
        // eslint-disable-next-line 
    }, [])
    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            {tenant ? <Fragment /> : <PageTitle title={"Maintenances"} />}
            {!loading && data.length ?
                <ToolBar
                    columnsFilter={'maintenances'}
                    columns={tenant ? undefined : columns}
                    noSearch
                    addTitle={'Add Maintenance'}
                    noAddButton
                    addPage={'/new_maintenance'}
                    refreshing={refreshing}
                    onRefresh={() => getPropertyMaintenances({ refreshing: true })}
                />
                : null}
            {
                !data?.length || loading ?
                    <NoData
                        message={`You don't have any maintenance.`}
                        createMessage={tenant ? undefined : `Add Maintenance`}
                        onCreate={tenant ? undefined : () => navigate('/new_maintenance')}
                        loading={loading}
                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            DisplayColums.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map((datam, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={datam.title}>
                                                    {
                                                        DisplayColums.map(({ label, name, isDate }, x) => {
                                                            let value = (datam as any)[name] || 'None'
                                                            value = isDate ? moment(value).format('DD/MM/YYYY') : value
                                                            return (
                                                                <TableCell key={label + '' + datam._id} /*align={column.align}*/>
                                                                    {
                                                                        name === 'action' ?
                                                                            <MenuListPop
                                                                                menuList={menuList}
                                                                                onSelect={option => {
                                                                                    if (option === 'Edit')
                                                                                        navigate('/new_maintenance', { state: { record: datam } })
                                                                                    else if (option === 'Delete')
                                                                                        setState({
                                                                                            ...state,
                                                                                            showDeleteModal: datam
                                                                                        })
                                                                                }}
                                                                            /> :
                                                                            value
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
            }

            {
                showDeleteModal ?
                    <DeleteModal
                        item={showDeleteModal}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={() => {
                            const itemIndex = data?.findIndex(q => q._id === showDeleteModal?._id) as number
                            const Items = [...data as MaintenanceProps[]]
                            Items?.splice(itemIndex, 1)
                            setState({
                                ...state,
                                showDeleteModal: null,
                                data: Items
                            })

                        }}
                        deleteField={'_id'}
                        page='Maintenance'
                        module={'Maintenances'}
                        itemName={showDeleteModal.title}
                        maintenanceId={showDeleteModal._id}
                    /> :
                    <Fragment />

            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Maintenances)