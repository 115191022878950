import { Box, Button, ListItem, Typography } from '@mui/material'
import { FC, Fragment, useState } from 'react'
import { PropertyProps } from '../../types/properties'
import { numberWithCommas } from '../../helpers'
import { DeleteOutlineOutlined, Edit } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { Connect, mapDispatchToProps, mapStateToProps } from '../../Redux'
import DeleteModal from '../Common/deletePrompt'

interface StateProps {
    showDeleteModal: null | PropertyProps
}
interface Props {
    item: PropertyProps
    selectedProperty?: PropertyProps
    setRedux?: (data: any) => void
    properties?: PropertyProps[]
}
const PropertyInfo: FC<Props> = ({
    item,
    selectedProperty,
    properties,
    setRedux
}) => {
    const [state, setState] = useState<StateProps>({
        showDeleteModal: null
    })
    const labelStyles = {
        fontSize: 14,
        mb: 0.5
    }
    const valueStyles = {
        fontWeight: 'bold',
        fontSize: 15,
    }
    const navigate = useNavigate()

    const { totalUnits, occupiedUnits, placeName, expectedRevenue, _id, arrears, currentMonthRevenue, arrearUnits, logo, name, location } = item
    const { showDeleteModal } = state
    const onSetAsDefault = () => {
        if (setRedux)
            setRedux({
                selectedProperty: {
                    _id,
                    name,
                    logo
                }
            })
        navigate('/dashboard')
    }
    return (
        <ListItem sx={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', mb: 3, pt: 3, pb: 3 }}>
            <Button onClick={onSetAsDefault}>
                <img src={logo} style={{
                    height: 250,
                    width: 250,
                    objectFit: 'cover',
                }} alt={''} />
            </Button>
            <Box sx={{
                ml: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1
            }}>
                <Box>
                    <Typography variant="h6">{name}</Typography>
                    <Typography sx={{
                        marginBottom: 4
                    }} variant="body1">{placeName ? placeName + ',' : ''} {location?.locName}</Typography>
                    <Typography sx={labelStyles} variant="body2">Total Units:<span style={valueStyles}> {totalUnits}</span></Typography>
                    <Typography sx={labelStyles} variant="body2">Occupied Units: <span style={valueStyles}> {occupiedUnits} ({numberWithCommas(occupiedUnits * 100 / totalUnits)}%)</span></Typography>
                    <Typography sx={labelStyles} variant="body2">Expected Revenue: <span style={valueStyles}>Ksh {numberWithCommas(expectedRevenue)}</span></Typography>
                    <Typography sx={labelStyles} variant="body2">Collected Revenue: <span style={valueStyles}>Ksh {numberWithCommas(currentMonthRevenue)} ({numberWithCommas(currentMonthRevenue * 100 / expectedRevenue)}%)</span></Typography>
                    <Typography sx={labelStyles} variant="body2">Arrears: <span style={valueStyles}>Ksh {numberWithCommas(arrears)}</span></Typography>
                    <Typography sx={labelStyles} variant="body2">Tenants with arrears: <span style={valueStyles}> {arrearUnits}</span></Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {
                        selectedProperty?._id !== _id ?
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    height: 50,
                                    fontSize: 16,
                                    mb: 2
                                }}
                                variant={'contained'}
                                onClick={onSetAsDefault}
                            >
                                Set as Default
                            </Button> :
                            null
                    }
                    <Button
                        sx={{
                            ml: 3,
                            textTransform: 'none',
                            mb: 2
                        }}
                        onClick={() => navigate('/new_property', { state: { item } })}
                    >
                        <Edit sx={{ mr: 0.5 }} /> Edit
                    </Button>
                    <Button
                        color={'error'}
                        sx={{
                            ml: 3,
                            textTransform: 'none'
                        }}
                        onClick={() => setState({ ...state, showDeleteModal: item })}
                    >
                        <DeleteOutlineOutlined sx={{ mr: 0.5 }} /> Delete
                    </Button>
                </Box>
            </Box>
            {
                showDeleteModal ?
                    <DeleteModal
                        item={item}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={() => {
                            setState({ ...state, showDeleteModal: null })
                            const propertyIndex = properties?.findIndex(q => q._id === item._id) as number
                            const Properties = [...properties as PropertyProps[]]
                            Properties?.splice(propertyIndex, 1)
                            const updateObj = selectedProperty?._id === item._id ? {
                                properties: Properties,
                                selectedProperty: null
                            } : {
                                properties: Properties
                            }
                            if (setRedux)
                                setRedux(updateObj)
                        }}
                        page='Property'
                        module={'Properties'}
                        itemName={showDeleteModal.name}
                        propertyId={showDeleteModal._id}
                    /> :
                    <Fragment />

            }
        </ListItem>
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(PropertyInfo)