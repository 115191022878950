import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import columns from "./columns"; 
import Toolbar from "../../Common/Toolbar";
import MenuListPop from "../../Common/menu";
import { Fragment } from "react";
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
interface UserRolesProps {
  name: string;
  role: string;
  action: string;
}

const rolesData :UserRolesProps[]= [
  { name: "Debt Collector", role: "Collector", action: "Follow up on debts" },
  { name: "Accountant", role: "Accountant", action: "Manage financial records" },
  { name: "Cleaning Company", role: "Cleaning Staff", action: "Perform cleaning tasks" }
];
const MenuList=[
  {label:'Edit',icon:<EditOutlined/>},
  {label:'Delete',icon:<DeleteOutlineOutlined/>}

]
const Users = () => {
  return (
    <Box>
      <Toolbar 
      addTitle={'Add Role'}
      addPage={'/NewUserRole'} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>{column.label}

                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Users;
