const columns = [
    {
        label: 'Name',
        name: 'name'
    },
    {
        label: 'Amount Per Month (Ksh)',
        name: 'amountPerMonth',
        isNumber:true
    },
    {
        label: 'Cost per Unit (Ksh)',
        name: 'costPerUnit',
        isNumber:true
    },
    {
        label: 'To be Paid with Rent',
        name: 'toBePaidWithRent'
    },
    {
        label: 'Paybill Number',
        name: 'payBillNumber'
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns