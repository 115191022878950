import React from 'react';
import { Box, Container, List, ListItem, Typography } from '@mui/material';
import PageTitle from '../Common/PageTitle';
import Copyright from '../Common/Copyright';

interface Props { }

const termsAndConditions = [
    {
        title: 'Acceptance of Terms',
        content: 'By using this app, users agree to comply with these terms and conditions. Continued use of the app constitutes acceptance of any updates or modifications to the terms. Users should regularly review the terms for any changes.'
    },
    {
        title: 'User Eligibility',
        content: 'Users must be at least 18 years old and capable of forming a legally binding contract to use the app. Landlords, tenants, and service providers are all welcome to use the platform. However, users under 18 years of age must seek parental or guardian consent before accessing the app.'
    },
    {
        title: 'Account Registration',
        content: 'Users are required to create an account to access certain features of the app. During registration, users must provide accurate and up-to-date information. They are responsible for maintaining the security of their account credentials and must not share their login details with others.'
    },
    {
        title: 'User Responsibilities',
        content: 'Users agree to use the app in accordance with all applicable laws and regulations. They are responsible for any content they post or transmit through the app and must not engage in any illegal, harmful, or abusive behavior. Users should respect the rights of others and refrain from violating any intellectual property or privacy rights.'
    },
    {
        title: 'Property Listings',
        content: 'Landlords are solely responsible for the accuracy and legality of the property listings they create on the app. They must ensure that they have the authority to rent or lease the properties they list. Any misrepresentation or fraudulent activity regarding property listings may result in account suspension or legal action.'
    },
    {
        title: 'Tenant Responsibilities',
        content: 'Tenants agree to abide by the terms of their lease agreements and to treat rental properties with care and respect. They must communicate any issues or concerns with the landlord or property manager in a timely manner. Tenants should adhere to community guidelines and regulations while residing in the rental property.'
    },
    {
        title: 'Service Providers',
        content: 'Third-party service providers, such as plumbers and laundry shops, are responsible for the quality and timeliness of the services they provide. They must adhere to any agreements they enter into with landlords or tenants through the app. Service providers should maintain professional conduct and ensure customer satisfaction.'
    },
    {
        title: 'Payment Terms',
        content: 'Users agree to pay any fees or charges associated with the use of premium features or services within the app. Payments must be made in accordance with the app\'s payment policies. Failure to pay outstanding fees may result in account suspension or termination.'
    },
    {
        title: 'Intellectual Property',
        content: 'The app and its contents are protected by copyright and other intellectual property laws. Users may not reproduce, distribute, or modify any part of the app without the developer\'s permission. Any unauthorized use of the app\'s intellectual property may result in legal action.'
    },
    {
        title: 'Privacy Policy',
        content: 'Users\' personal information will be handled in accordance with the app\'s privacy policy, which outlines how data is collected, stored, and used. The app collects and processes user data for the purpose of providing and improving its services. Users have the right to review and request changes to their personal information.'
    },
    {
        title: 'Termination',
        content: 'The developer reserves the right to terminate or suspend users\' accounts at any time for violations of these terms and conditions or for any other reason deemed necessary. Users will be notified of any account terminations or suspensions via email or within the app. Upon termination, users must cease all use of the app and may lose access to their account data.'
    },
    {
        title: 'Disclaimer of Liability',
        content: 'The developer is not liable for any damages or losses incurred by users as a result of their use of the app. Users use the app at their own risk. While the developer strives to provide accurate and reliable information, it does not guarantee the completeness or accuracy of the content available on the app.'
    },
    {
        title: 'Governing Law',
        content: 'These terms and conditions are governed by the laws of Kenya, and any disputes will be resolved in accordance with Kenyan law. Any legal proceedings arising from the use of the app shall be conducted in the courts of Kenya. Users agree to submit to the jurisdiction of these courts.'
    },
    {
        title: 'Acknowledgement',
        content: 'By agreeing to these terms and conditions, users acknowledge that they have read and understood their rights and responsibilities while using the property management app. Users should contact the developer if they have any questions or concerns regarding the terms or their use of the app.'
    },
    {
        title: 'Feedback and Suggestions',
        content: 'We encourage users to provide feedback, suggestions, or ideas for improving the app\'s functionality and user experience. Users can submit feedback through designated channels or contact our support team. While we appreciate user input, we reserve the right to use or implement feedback at our discretion and without obligation to compensate users.',
    },
    {
        title: 'Community Guidelines',
        content: 'Users are expected to adhere to community guidelines while using the app. This includes refraining from posting offensive, abusive, or discriminatory content, and respecting the rights and privacy of other users. Violations of community guidelines may result in account suspension or termination.',
    },
    {
        title: 'Dispute Resolution',
        content: 'In the event of disputes between users, we encourage parties to attempt to resolve the issue amicably through communication and negotiation. If a resolution cannot be reached, users may seek mediation or arbitration as a means of dispute resolution. We are not responsible for resolving disputes between users but may provide assistance or guidance upon request.',
    },
    {
        title: 'Indemnification',
        content: 'Users agree to indemnify and hold harmless the app\'s developers, owners, and affiliates from any claims, damages, or liabilities arising from their use of the app or violation of these terms and conditions. This includes legal fees and expenses incurred as a result of user actions or content posted on the app.',
    },
    {
        title: 'Modifications to the App',
        content: 'We reserve the right to modify or update the app\'s features, functionality, or user interface at any time without prior notice. Users may be notified of significant changes via email or in-app notifications. Continued use of the app after such modifications constitutes acceptance of the updated terms and conditions.',
    },
    {
        title: 'Limitation of Liability',
        content: 'To the fullest extent permitted by law, we disclaim any liability for damages or losses incurred by users as a result of their use of the app. This includes direct, indirect, incidental, or consequential damages, regardless of the cause of action. Users use the app at their own risk and should exercise caution when interacting with other users or engaging in transactions through the app.',
    },
    {
        title: 'Termination of Service',
        content: 'We reserve the right to terminate or suspend users\' access to the app at any time and for any reason without prior notice. This may include violations of these terms and conditions, suspicious account activity, or legal requirements. Users will be notified of account terminations or suspensions via email or in-app notifications.',
    },
    {
        title: 'Effect of Invalidity',
        content: 'If any provision of these terms and conditions is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect. Any invalid or unenforceable provision will be interpreted to reflect the original intent of the parties to the fullest extent permitted by law.',
    },
    {
        title: 'Governing Law and Jurisdiction',
        content: 'These terms and conditions are governed by the laws of the jurisdiction in which the app is operated, without regard to its conflict of law provisions. Any disputes arising from or related to these terms and conditions will be subject to the exclusive jurisdiction of the courts in that jurisdiction.',
    }
];



const Terms_conditions: React.FC<Props> = () => {
    return (
        <Container>
            <PageTitle title={'Terms And Conditions'} />
            <List sx={{ mt: 3 }}>
                {termsAndConditions.map((term, index) => (
                    <ListItem key={index} >
                        <Typography variant="body1">
                            <strong>{term.title}:</strong> {term.content}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default Terms_conditions;
