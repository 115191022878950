import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { samePageLinkNavigation } from "../Tenants/Tenant";
import General from './general'
import Users from "./Users";
import PageTitle from "../Common/PageTitle";
import { useNavigate } from "react-router-dom";
interface Props { }
interface StateProps {
  value: number;
}
const Account = (props: Props) => {
  const [state, setState] = useState<StateProps>({
    value: 0,
  });
  const { value } = state;
  const tabs = [
    { label: "General", page: <General /> },
    { label: "Users", page: <Users /> }
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ))
    ) {
      setState({
        ...state,
        value: newValue
      });
    }
  };
  const navigate =useNavigate()
  return (
    <Box
      sx={{
        height: "90vh",
        pr: 5,
      }}
    >
      <PageTitle title={"Account"} />
      <Button onClick={()=>navigate('/pay_for_plan')}>Go to plans</Button>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Roles tabs "
        role="navigation"
        sx={{ mt: 3, ml: 5 }}
      >
        {tabs.map(({ label }, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>
      {tabs[value].page}
    </Box>
  )
}

export default Account