import { Stack } from '@mui/material';
import { LineChart, PieChart, pieArcLabelClasses } from '@mui/x-charts';
import { DefaultizedPieValueType } from '@mui/x-charts/models/seriesType';
import { FC } from 'react';
import { PieProps } from '../../types';


export const TinyLineChart = () => {
    return (
        <LineChart
            xAxis={[{ data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
            series={[
                {
                    data: [2, 3, 5.5, 8.5, 1.5, 5, 1, 4, 3, 8],
                    showMark: ({ index }) => index % 2 === 0,
                },
                {
                    data: [5, 10, 25.5, 4.5, 7.5, 7, 32, 6, 7, 5],
                    showMark: ({ index }) => index % 2 === 0,
                }
            ]}
            width={600}
            height={500}
        />
    );
}

export const Pie: FC<PieProps> = ({
    items
}) => {


    const getArcLabel = (params: DefaultizedPieValueType) => {
        const TOTAL = items.map((item) => item.value).reduce((a, b) => a + b, 0);
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };


    return (
        <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="space-between"
            sx={{ width: '100%' }}
        >
            <PieChart
                series={[
                    {
                        data: items,
                        arcLabel: getArcLabel,
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 14,
                    },
                }}
                width={400}
                height={200}
                margin={{ right: 200 }}
            />
        </Stack>
    );
}
