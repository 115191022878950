import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material'
import PageTitle from '../Common/PageTitle'
import { FC, Fragment, useEffect, useState } from 'react'
import { LinkTab, samePageLinkNavigation } from '../Tenants/Tenant'
import Toolbar from '../Common/Toolbar'
import { TenantProps } from '../../types/tenants'
import { paymentsDeductions, arrearsDeductions } from './columns'
import NoData from '../Common/NoData'
import { Apis, numberWithCommas } from '../../helpers'
import { Connect, mapStateToProps } from '../../Redux'
import { PropertyProps } from '../../types/properties'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment'

interface StateProps {
    value: number
    loading: boolean
    refreshing: boolean
    data: any[]
    selectedAccordionIndex: null | number
}
interface Props {
    // tablesColumnsFilters?: {
    //     payments: string[]
    // }
    selectedProperty?: PropertyProps
    tenant?: TenantProps
}
const Deductions: FC<Props> = ({
    tenant,
    selectedProperty
}) => {
    const tabs = [
        { label: 'Payments' },
        { label: 'Balance' }

    ]
    const [state, setState] = useState<StateProps>({
        value: 0,
        loading: true,
        data: [],
        refreshing: false,
        selectedAccordionIndex: null
    })
    const { value, loading, data, refreshing, selectedAccordionIndex } = state
    const columns = value === 0 ? paymentsDeductions : arrearsDeductions
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ))
        ) {
            setState({
                ...state,
                value: newValue
            });
        }
    };
    const getDeductions = ({ refreshing }: { refreshing?: boolean }) => {
        if (refreshing)
            setState({
                ...state,
                refreshing: true
            })
        Apis({
            module: 'Payments',
            action: value === 0 ? 'getPaymentDeductions' : 'getBalanceDeductions',
            propertId: selectedProperty?._id,
            tenantId: tenant?._id
        }).then((payments: any) => {
            setState({
                ...state,
                data: payments,
                refreshing: false,
                loading: false
            })
        }).catch(() => {
            setState({
                ...state,
                refreshing: false,
                loading: false
            })
        })
    }
    useEffect(() => {
        getDeductions({})

        return () => {

        }
    }, [])

    return (
        <Box sx={{
            height: '90vh',
            pr: 5
        }}>
            {!tenant ?
                <PageTitle title='Deductions' /> :
                <Fragment />
            }
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                role="navigation"
                sx={{ mt: 3, ml: 5 }}
            >
                {
                    tabs.map(({ label }) => (
                        <LinkTab key={label} label={label} />
                    ))
                }

            </Tabs>
            {!loading && data.length ?
                <Toolbar
                    columnsFilter={'payments'}
                    columns={tenant ? undefined : columns}
                    noAddButton
                    noSearch={Boolean(tenant)}
                    refreshing={refreshing}
                    onRefresh={() => getDeductions({ refreshing: true })}
                />
                : null}
            {
                !data?.length || loading ?
                    <NoData
                        loading={loading}
                    /> :
                    <Box sx={{
                        height: '70vh',
                        overflow: 'hidden',
                        overflowY: 'auto'
                    }}>
                        {
                            data.map(({ _id, User, payment, title, description, unit, deductions }: any, i) => (
                                value === 0 ?
                                    <Accordion
                                        key={_id} expanded={selectedAccordionIndex === i}

                                        onChange={() => {
                                            setState({
                                                ...state,
                                                selectedAccordionIndex: selectedAccordionIndex === i ? null : i
                                            })
                                        }}
                                        sx={{ mt: 2, boxShadow: 'none' }}

                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"

                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                flex: 1,
                                                alignItems: 'center'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center'
                                                }}>
                                                    {
                                                        tenant ?
                                                            <Fragment /> :
                                                            <Avatar src={User?.image} />
                                                    }
                                                    <Box sx={{
                                                        pl: 2
                                                    }}>
                                                        {
                                                            tenant ?
                                                                <Fragment /> : <Typography sx={{
                                                                    fontWeight: 'bold'
                                                                }}>{User?.name}</Typography>
                                                        }
                                                        <Typography sx={{ fontSize: 13.5, mt: 2 }}>Amount: Kes {numberWithCommas(payment.amount)}</Typography>
                                                        <Typography sx={{ fontSize: 13.5 }}>Title: {title}</Typography>
                                                        <Typography sx={{ fontSize: 13.5 }}>Description: {description}</Typography>
                                                        <Typography sx={{ fontSize: 13.5 }}>Unit: {unit.name} {unit?.unitLabel?.label} </Typography>
                                                    </Box>
                                                </Box>
                                                <Typography sx={{ fontSize: 13, mr: 5 }}>{moment(payment.dateOfPayment).format('DD/MM/YYYY HH:mm')}</Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <TableContainer >
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {
                                                                columns.map(({ label }: any) => (
                                                                    <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                                        {label}
                                                                    </TableCell>
                                                                ))
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            deductions.map((datam: any) => {
                                                                return (
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={datam._id}>
                                                                        {
                                                                            columns.map(({ label, name, isDate, isNumber }, x) => {
                                                                                let value = (datam as any)[name] || 'None'
                                                                                value = isDate ? moment(value).format('DD/MM/YYYY HH:mm') : isNumber ? numberWithCommas(value) : value
                                                                                return (
                                                                                    <TableCell key={label + '' + datam._id} /*align={column.align}*/>
                                                                                        {value}
                                                                                    </TableCell>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </AccordionDetails>
                                    </Accordion> :
                                    <Fragment />
                            ))
                        }
                    </Box>
            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Deductions)