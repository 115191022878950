import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material"
import PageTitle from "../Common/PageTitle"
import { Connect, mapStateToProps } from "../../Redux"
import { FC, Fragment, useEffect, useState } from "react"
import { Circle, Verified } from "@mui/icons-material"
import { Apis } from "../../helpers"

export const colors = {
    Bronze: '#CD7F32',
    Silver: '#C0C0C0',
    Gold: '#FFD700'
};
const months = [...Array.from({ length: 11 }, (_, index) => index + 1).map(q => q + ' month' + (q > 9 ? 's' : '')), '1 Year']
interface Props {
    loginSelectedPlan?: {
        plan: string
        features: string[]
    }
}
interface StateProps {
    plan: {
        name: string,
        minUnits: number,
        maxUnits: number,
        features: {
            name: string,
            price: number
        }[]

    } | null
}
const Plans: FC<Props> = ({
    loginSelectedPlan
}) => {
    const { features } = loginSelectedPlan || {}
    const [state, setState] = useState<StateProps>({
        plan: null
    })
    const { plan } = state
    const getPlan = () => {
        Apis({
            module: 'Users',
            action: 'getPlan',
            name: loginSelectedPlan?.plan
        }).then((resp: any) => {
            setState({
                ...state,
                plan: resp
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    const getTotal = () => {
        let total = 0
        plan?.features.forEach((feature, i)=>{
            total = total + (i ===0 || loginSelectedPlan?.features.includes(feature.name)?feature.price:0)
        })
         
        return total
    }
    const totalPrice = getTotal()
    useEffect(() => {
        getPlan()

        return () => {

        }
    }, [])

    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            <PageTitle title="Subscribe" />

            <Box sx={{
                mt: 10,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <Verified sx={{ color: (colors as any)[(loginSelectedPlan)?.plan as any] }} />
                <Typography sx={{ ml: 1, fontWeight: 'bold' }}>{loginSelectedPlan?.plan}</Typography>
            </Box>
            <Box sx={{
                pl: 5
            }}>
                {
                    features?.length ?
                        <Fragment>
                            <Typography sx={{
                                mt: 5,
                                fontWeight: 'bold',
                                color: '#656565',
                                mb: 2
                            }}>Additional Features</Typography>
                            {
                                features.map((feature, i) => (
                                    <Box key={i} sx={{
                                        mb: 1,
                                        pl: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <Circle sx={{ fontSize: 10, mr: 1 }} />
                                        <Typography>{feature}</Typography>
                                    </Box>
                                ))
                            }
                        </Fragment> :
                        <Fragment />
                }
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    // defaultValue={unit && unit[field] || ''}
                    sx={{ mt: 5, width: 300 }}
                    disableClearable
                    options={months}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={'Plan Duration'}
                            // onFocus={() =>  changeInput()}
                            required
                            // error={Boolean(error)}
                            // helperText={error}
                            variant={'standard'}
                            InputProps={{
                                ...params.InputProps,
                                type: 'search'
                            }}
                        />
                    )}
                />
                <Box>
                    <Typography>{totalPrice}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default Connect(mapStateToProps)(Plans)