import { FC, Fragment, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ArrowBack, ArrowDropDown } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import { terms_urls } from './Drawer';
import { Connect, mapStateToProps } from '../../Redux';
import { UserProps } from '../../types/user';

interface Props {
    title: string
    backBtn?: boolean
    User?: UserProps
}
const PageTitle: FC<Props> = ({
    title,
    backBtn,
    User
}) => {
    const navigate = useNavigate();
    useEffect(() => {

        return () => {
        }
    }, [])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            pr: 10,
            mb: 1
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1
            }}>
                {
                    backBtn  || (terms_urls.includes(window.location.pathname)&&Boolean(User))
                     ?
                        <Button
                            sx={{ r: 1 }}
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBack />
                        </Button> :
                        null
                }
                <Typography sx={{
                    fontSize: 25,
                    fontWeight: 'bold'
                }}>{title}</Typography>
            </Box>
            {
                title === 'Dashboard' ?
                    <Button
                        sx={{
                            textTransform: 'none'
                        }}
                        variant={'outlined'}
                    >
                        This Month
                        <ArrowDropDown />
                    </Button> :
                    <Fragment />
            }
        </Box>
    )
}

export default Connect(mapStateToProps)(PageTitle)