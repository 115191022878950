 const columns = [
    {
        label: 'Name',
        name: 'name'
    },
    {
        label: 'Description',
        name: 'description'
    },
    {
        label: 'Number of Units',
        name: 'numberOfUnits'
    },
    {
        label: 'Number of Labeled Units',
        name: 'numberOfLabeledUnits'
    },
    {
        label: 'Vacant Units',
        name: 'vacantUnits'
    },
    {
        label: 'Units under Maintenance',
        name: 'maintenceUnits'
    },
    {
        label: 'BnBs',
        name: 'bnbs'
    },
    {
        label: 'Occupied Units',
        name: 'occupiedUnits',
        isNumber:true
    },
    {
        label: 'Price Per Month (Ksh)',
        name: 'pricePerMonth',
        isNumber:true
    },
    {
        label: 'Deposit (Ksh)',
        name: 'deposit',
        isNumber:true
    },
    {
        label: 'Expected Revenue (Ksh)',
        name: 'expectedRevenue',
        isNumber:true
    },
    {
        label: 'Revenue This Month (Ksh)',
        name: 'thisMonthRevenue',
        isNumber:true
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns