import { LoadingButton } from "@mui/lab"
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { InputProps } from "../../types"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { titleOptions } from "../Payments/new"
import { Apis } from "../../helpers"
import { Connect, mapStateToProps } from "../../Redux"
import { PropertyProps } from "../../types/properties"
import { TenantProps } from "../../types/tenants"
import { EventsEmit } from "../../configs/events"

interface Props {
    close?: () => void
    onCreateArrear?: () => void
    selectedProperty?: PropertyProps
    tenant?: TenantProps
}
interface StateProps {
    inputs: InputProps[]
    submitting: boolean
}
const NewArrear: FC<Props> = ({
    close,
    onCreateArrear,
    selectedProperty,
    tenant
}) => {
    const initialInputs: InputProps[] = [
        { label: 'Title', value: '', field: 'description', required: true },
        { label: 'Description', value: '', field: 'notes', required: true },
        { label: 'Amount', value: '', type: 'number', field: 'amount', required: true },
        { label: 'Due date', value: '', type: 'date', field: 'dueDate', required: true }
    ]
    const [state, setState] = useState<StateProps>({
        submitting: false,
        inputs: initialInputs
    }),
        { submitting, inputs } = state,
        onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            setState({
                ...state,
                submitting: true
            })
            Apis({
                module: 'Payments',
                action: 'addArrear',
                ...formJson,
                propertId: selectedProperty?._id,
                tenantId: tenant?._id,
                unitId: tenant?.unit?._id,
                unitLabelId: tenant?.labelId
            }).then(({ message }: any) => {
                if(onCreateArrear)
                onCreateArrear()
                EventsEmit('app',
                    {
                        info: {
                            message,
                            status: 'success'
                        }
                    })
            }).catch((err) => {
                EventsEmit('app',
                    {
                        info: {
                            message: 'Review not sent.',
                            status: 'error'
                        }
                    })
                setState({
                    ...state,
                    submitting: false
                })
            })
        }
    useEffect(() => {
        return () => {

        }
    }, [])

    return (
        <Dialog
            open
            onClose={close}
            PaperProps={{
                component: 'form',
                onSubmit,
            }}
        >
            <DialogTitle>  Add Arrear</DialogTitle>
            <DialogContent sx={{ width: 500, height: 450 }}>
                {
                    inputs.map(({ label, type, required, value, field, error, options }, i: number) => (
                        <Grid sx={{ mb: 1, pt: 2 }} key={label}>
                            {
                                field === 'description' ?
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        // defaultValue={unit && unit[field] || ''}
                                        disableClearable
                                        options={titleOptions.sort((a, b) => a > b ? 1 : a < b ? -1 : 0)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={label + ' '}
                                                name={field}
                                                // onFocus={() =>  changeInput()}
                                                required
                                                error={Boolean(error)}
                                                helperText={error}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search'
                                                }}
                                            />
                                        )}
                                    /> :
                                    type === 'date' ?
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                name={field}
                                                label={label}
                                                format={'DD/MM/YYYY'}
                                                sx={{ mt: 1 }}
                                            />
                                        </LocalizationProvider> :
                                        <TextField
                                            autoComplete="given-name"
                                            name={field}
                                            required={required}
                                            // defaultValue={record && record[field]}
                                            fullWidth
                                            label={label}
                                            type={type || 'text'}
                                            autoFocus
                                            error={Boolean(error)}
                                            helperText={error}
                                            maxRows={10}
                                            minRows={5}
                                            multiline={label === 'Description'}
                                        />
                            }
                        </Grid>
                    ))
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    sx={{ textTransform: 'none' }}
                    onClick={close}
                >Cancel</Button>
                <LoadingButton
                    sx={{ textTransform: 'none' }}
                    type="submit"
                    loading={submitting}
                >Submit</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default Connect(mapStateToProps)(NewArrear)