import PageTitle from "../Common/PageTitle"
import ToolBar from "../Common/Toolbar"
import { FC, Fragment, useEffect, useState } from "react"
import columns from "./columns"
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import MenuListPop from "../Common/menu"
import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material"
import { Apis, numberWithCommas } from "../../helpers"
import { PropertyProps } from "../../types/properties"
import NoData from "../Common/NoData"
import { useNavigate } from "react-router-dom"
import { Connect, mapStateToProps } from "../../Redux"
import moment from 'moment'
import DeleteModal from '../Common/deletePrompt'
import { InvoiceProps } from "../../types/invoice"
import { TenantProps } from "../../types/tenants"


interface StateProps {
    data: InvoiceProps[]
    loading: boolean
    showDeleteModal: null | InvoiceProps
    refreshing: boolean
}
interface Props {
    tablesColumnsFilters?: {
        invoices: string[]
    }
    tenant?: TenantProps
    selectedProperty?: PropertyProps
}
const Invoices: FC<Props> = ({
    selectedProperty,
    tablesColumnsFilters,
    tenant
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        showDeleteModal: null,
        loading: true,
        refreshing: false
    })
    const navigate = useNavigate()

    const menuList = [
        { label: 'Edit', icon: <EditOutlined /> },
        { label: 'Delete', icon: <DeleteOutlineOutlined /> }
    ]
    const { data, loading, showDeleteModal, refreshing } = state
    const DisplayColums = columns.filter(col => tablesColumnsFilters?.invoices.includes(col.name))


    const getPropertyInvoices = ({
        refreshing
    }: any) => {
        if (refreshing)
            setState({ ...state, refreshing: true })
        Apis({
            module: 'Invoices',
            action: 'getPropertyInvoices',
            propertId: selectedProperty?._id
        }).then((data: any) => {
            setState({ ...state, data, loading: false })
        }).catch(() => {
            setState({
                ...state,
                loading: false
            })
        })
    }
    useEffect(() => {
        getPropertyInvoices({})
        // eslint-disable-next-line 
    }, [])
    return (
        <Box sx={{ height: '100%', pr: 5 }}>
             {tenant ? <Fragment /> : <PageTitle title={"Invoices"} />}
            {!loading && data.length ?
                <ToolBar
                    columnsFilter={'invoices'}
                    columns={columns}
                    addTitle={'Create Invoice'}
                    addPage={'/new_invoice'}
                    refreshing={refreshing}
                    onRefresh={() => getPropertyInvoices({ refreshing: true })}
                />
                : null}
            {
                !data?.length || loading ?
                    <NoData
                        createMessage={tenant?undefined:`Create Invoice`}
                        onCreate={tenant?undefined:() => navigate('/new_invoice')}
                        loading={loading}
                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            DisplayColums.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map((datam, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={datam.title}>
                                                    {
                                                        DisplayColums.map(({ label, name, field, isDate, isNumber }, x) => {
                                                            let value = field && (datam as any)[name] ? (datam as any)[name][field] : (datam as any)[name] || 'None'
                                                            value = isDate ? moment(value).format('DD/MM/YYYY') : isNumber ? numberWithCommas(value) : value
                                                            return (
                                                                <TableCell key={label + '' + datam._id} /*align={column.align}*/>
                                                                    {
                                                                        name === 'action' ?
                                                                            <MenuListPop menuList={menuList} onSelect={option => {
                                                                                if (option === 'Edit')
                                                                                    navigate('/new_maintenance', { state: { record: datam } })
                                                                                else if (option === 'Delete')
                                                                                    setState({
                                                                                        ...state,
                                                                                        showDeleteModal: datam
                                                                                    })
                                                                            }} /> :
                                                                            value
                                                                    }
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
            }

            {
                showDeleteModal ?
                    <DeleteModal
                        item={showDeleteModal}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={() => {
                            const itemIndex = data?.findIndex(q => q._id === showDeleteModal?._id) as number
                            const Items = [...data as InvoiceProps[]]
                            Items?.splice(itemIndex, 1)
                            setState({
                                ...state,
                                showDeleteModal: null,
                                data: Items
                            })

                        }}
                        deleteField={'_id'}
                        page='Maintenance'
                        module={'Maintenances'}
                        itemName={showDeleteModal.title}
                        maintenanceId={showDeleteModal._id}
                    /> :
                    <Fragment />

            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Invoices)