import { Connect, mapStateToProps } from "../../Redux";
import { FC, Fragment, useEffect, useState } from "react"
import PageTitle from "../Common/PageTitle";
import Toolbar from "../Common/Toolbar";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"


 const  Expenditure = () => {

 const expenditures = [
  {"date": "2024-03-14", "reference_no": "REF001", "category": "Water Bill", "description": "Monthly water bill for property X", "amount": 150.00},
  {"date": "2024-03-14", "reference_no": "REF002", "category": "Electricity Bill", "description": "Electricity bill for property X", "amount": 200.00},
  {"date": "2024-03-14", "reference_no": "REF003", "category": "Garbage Collection Fees", "description": "Quarterly garbage collection fees", "amount": 50.00},
  {"date": "2024-03-14", "reference_no": "REF004", "category": "Maintenance", "description": "Repair work on property X", "amount": 300.00},
  {"date": "2024-03-14", "reference_no": "REF005", "category": "Tax", "description": "Annual property tax", "amount": 500.00}
]

  
  return (
    <Box>
      <PageTitle title={'Expenditures'} />
      <Toolbar
      addTitle={'Create Expenditure'}
      addPage={'/new_expenditure'}
    />

    <TableContainer component={Paper}>
    <Table >
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Reference No</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="right">Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {expenditures.map((expenditure, index) => (
          <TableRow key={index}>
            <TableCell>{expenditure.date}</TableCell>
            <TableCell>{expenditure.reference_no}</TableCell>
            <TableCell>{expenditure.category}</TableCell>
            <TableCell>{expenditure.description}</TableCell>
            <TableCell align="right">{expenditure.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
   </Box>

  )
}



export default Connect(mapStateToProps)(Expenditure)
