import { Box } from '@mui/material'
import PageTitle from '../../Common/PageTitle'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import General from './general';
import Payments from '../../Payments';
import Arrears from '../../Arrears';
import { useLocation } from 'react-router-dom';
import Maintenances from '../../Maintenances';
import Invoices from '../../Invoices';
import Deductions from '../../Deductions';

interface StateProps {
    value: number
}
export function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

interface LinkTabProps {
    label?: string;
    href?: string;
    selected?: boolean;
}

export const LinkTab = (props: LinkTabProps) => {
    return (
        <Tab
            component="a"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                // Routing libraries handle this, you can remove the onClick handle when using them.
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            aria-current={props.selected && 'page'}
            {...props}
            sx={{ textTransform: 'none' }}
        />
    );
}

const Tenant = () => {
    const location = useLocation()
    const { user } = location.state
    const tabs = [
        { label: 'Account Info', page: <General user={user} /> },
        { label: 'Maintenances', page: <Maintenances tenant={user} /> },
        { label: 'Payments', page: <Payments tenant={user} /> },
        { label: 'Invoices', page: <Invoices tenant={user} /> },
        { label: 'Arrears', page: <Arrears tenant={user} /> },
        { label: 'Deductions', page: <Deductions tenant={user} /> },
    ]
    const [state, setState] = useState<StateProps>({
        value: 0
    })
    const { value } = state
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ))
        ) {
            setState({
                ...state,
                value: newValue
            });
        }
    };
    return (
        <Box>
            <PageTitle backBtn title={user.name} />
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                role="navigation"
                sx={{ mt: 3, ml: 5 }}
            >
                {
                    tabs.map(({ label }) => (
                        <LinkTab key={label} label={label} />
                    ))
                }

            </Tabs>
            <Box sx={{
                mt: 5,
                ml: 8,
                height: '70vh'
            }}>
                {tabs[value].page}
            </Box>
        </Box>
    )
}

export default Tenant