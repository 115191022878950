import PageTitle from '../Common/PageTitle'
import { Box, Checkbox, CircularProgress, Container, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { InputProps } from '../../types'
import { Apis } from '../../helpers'
import { Connect, mapStateToProps } from '../../Redux'
import { PropertyProps, UnitsProps } from '../../types/properties'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'
import { EventsEmit } from '../../configs/events'

interface Props {
    selectedProperty?: PropertyProps
}
interface StateProps {
    inputs: InputProps[]
    adding: boolean
    units: UnitsProps[]
    loadingUnits: boolean
    selectedUnit: UnitsProps | null
    vacant: boolean
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const NewUtility: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const { record, unit } = location?.state || {}
    const fomrRef = useRef(null)

    const initialInputs: InputProps[] = [
        { label: 'Unit', value: '', field: 'unit' },
        { label: 'Unit Label / House No.', value: '', field: 'unitLabel' },
        { label: 'Vacant', value: true, type: 'checkbox', field: 'vacant' }
    ]
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        adding: false,
        units: [],
        loadingUnits: true,
        selectedUnit: null,
        vacant: true
    })
    const navigate = useNavigate()

    const { inputs, adding, units, loadingUnits, selectedUnit, vacant } = state
    const changeInput = (input: string, obj: any) => {
        const Inputs = [...inputs]
        const inputIndex = inputs.findIndex(q => q.field === input)
        Inputs[inputIndex] = { ...Inputs[inputIndex], ...obj }
        setState({
            ...state,
            inputs: Inputs,
            adding: false
        })
    }
    //functions
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        obj.vacant = Boolean(obj.vacant)
        obj.unitLabels = obj.unitLabel.split(',').map((q: string) => q.trim())
        setState({ ...state, adding: true })
        Apis({
            module: 'Properties',
            action: 'addUpdateUnitLabels',
            propertId: selectedProperty?._id,
            unitLabelId: record?._id,
            ...obj,
            unitId: selectedUnit?._id || record?.unit?._id || unit?._id,
            oldUnitId: record?.unit?._id,
            oldUnitLabel: record?.unitLabel,
        }).then(({ message, error, field }: any) => {

            if (error)
                changeInput(field, { error })
            else {
                (fomrRef.current as any).reset()
                setState({ ...state, adding: false })
                EventsEmit('app', {
                    info: {
                        message,
                        status: 'success'
                    },
                })
                if (record?._id)
                    navigate(-1)
            }
        }).catch((e) => {
            setState({ ...state, adding: false })
        })
    };
    const getUnits = () => {
        Apis({
            module: 'Properties',
            action: 'getPropertyUnits',
            _id: selectedProperty?._id
        }).then((units: any) => {
            let updateObject: any = {
                units,
                loadingUnits: false
            }
            if (!record)
                updateObject = { ...updateObject, selectedUnit: units[0] }
            setState({
                ...state,
                vacant: !Boolean(record?.userId),
                ...updateObject
            })
        }).catch((err: any) => {
            setState({
                ...state,
                loadingUnits: false
            })
        })
    }
    useEffect(() => {
        getUnits()
        return () => {

        }
    }, [])
    return (
        <Fragment  >
            <PageTitle
                title={`${record?._id ? 'Update' : 'Add'} Unit Label`}
                backBtn
            />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={fomrRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, value, field, error }, i: number) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    key={field}
                                >
                                    {
                                        field === 'unit' ?
                                            <Fragment>
                                                <InputLabel sx={{ mt: -3 }} >{label}</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-name-label"
                                                    defaultValue={record?.unit?.name || unit?.name}
                                                    onChange={(event) => {
                                                        setState({
                                                            ...state,
                                                            selectedUnit: units.find(q => q.name === event.target.value) as UnitsProps
                                                        })
                                                    }}
                                                    input={<OutlinedInput
                                                        fullWidth
                                                        label={label}
                                                        endAdornment={
                                                            loadingUnits ?
                                                                <InputAdornment position="end">
                                                                    <CircularProgress size={25} />
                                                                </InputAdornment> :
                                                                <Fragment />
                                                        }
                                                    />}
                                                    MenuProps={MenuProps}
                                                >
                                                    {units.map(({ name }) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                        // style={getStyles(name, personName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Fragment>

                                            :
                                            type === 'checkbox' ?
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        disabled={vacant && !record?.userId}
                                                        onChange={(e) => {
                                                            setState({ ...state, [field]: e.target.checked })
                                                        }}
                                                        checked={vacant}
                                                        name={field} color="primary" />}
                                                    label={label}
                                                /> :
                                                <TextField
                                                    autoComplete="given-name"
                                                    name={field}
                                                    required={!['amountPerMonth', 'costPerUnit'].includes(field)}
                                                    fullWidth
                                                    label={label}
                                                    onFocus={() => changeInput(field, { error: null })}
                                                    type={type || 'text'}
                                                    defaultValue={record && record[field]}
                                                    error={Boolean(error)}
                                                    helperText={error || !Boolean(record?._id) && 'Add multiple by separating them with  comma.'}
                                                    maxRows={5}
                                                    minRows={1}
                                                    multiline={field === 'unitLabel'}
                                                />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={adding}
                    >
                        {`${record?._id ? 'Update' : 'Add'} Label`}
                    </LoadingButton>
                </Box>
            </Container>
        </Fragment>
    )
}

export default Connect(mapStateToProps)(NewUtility)