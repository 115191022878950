import { FC, Fragment, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Connect, mapStateToProps } from "../../Redux"
import { UserProps } from "../../types/user"

interface Props{
    loginSelectedPlan?:{
        plan:string
    },
    User?:UserProps
}

const LoggedIn:FC<Props> = ({
    loginSelectedPlan,
    User
}) => {
    const navigate = useNavigate()
    useEffect(() => {
        const url = !User?'/landinh.html':loginSelectedPlan?'/pay_for_plan':'/'
        navigate(url)
    }, [])
    return (
        <Fragment />

    )
}

export default Connect(mapStateToProps)(LoggedIn)