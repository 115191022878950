import PageTitle from '../Common/PageTitle'
import { Box, Checkbox, Container, FormControlLabel, Grid, TextField } from '@mui/material'
import { FC, Fragment, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { InputProps } from '../../types'
import { Apis } from '../../helpers'
import { Connect, mapStateToProps } from '../../Redux'
import { PropertyProps } from '../../types/properties'
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'
import { EventsEmit } from '../../configs/events'

interface Props {
    selectedProperty?: PropertyProps
}
interface StateProps {
    inputs: InputProps[]
    adding: boolean
    toBePaidWithRent: boolean
    fixedPayment: boolean
}
const NewUtility: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const { record } = location?.state || {}
    const fomrRef = useRef(null)

    const initialInputs: InputProps[] = [
        { label: 'Name', value: '', field: 'name' },
        { label: 'To be paid with Rent ', value: '', type: 'checkbox', field: 'toBePaidWithRent' },
        { label: 'Fixed Payment ', value: '', type: 'checkbox', field: 'fixedPayment' },
        { label: 'Amount Per Month', value: '', field: 'amountPerMonth' },
        { label: 'Cost per Unit (Ksh)', value: '', field: 'costPerUnit' },
        { label: 'Paybill Number', value: '', field: 'payBillNumber' }
    ]
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        adding: false,
        toBePaidWithRent: record?.toBePaidWithRent || false,
        fixedPayment: record?.fixedPayment || false
    })
    const navigate = useNavigate()

    const { inputs, adding, toBePaidWithRent, fixedPayment } = state
    //functions
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        setState({ ...state, adding: true })
        Apis({
            module: 'Properties',
            action: 'addUtility',
            ...obj,
            toBePaidWithRent,
            fixedPayment,
            propertId: selectedProperty?._id
        }).then(({ message }: any) => {
            (fomrRef.current as any).reset()
            setState({ ...state, adding: false })
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                },
            })
            if (record?._id)
                navigate(-1)
        }).catch((e) => {
            setState({ ...state, adding: false })
        })

    };
    return (
        <Fragment  >
            <PageTitle
                title={`${record?._id ? 'Update' : 'Add'} Utility`}
                backBtn
            />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={fomrRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, value, field, error }, i: number) => (
                                (toBePaidWithRent && ['accountToPayTo', 'payBillNumber'].includes(field)) ||
                                    (fixedPayment && ['costPerUnit'].includes(field)) ||
                                    (!fixedPayment && ['amountPerMonth'].includes(field))
                                    ?
                                    <Fragment key={field} /> :
                                    <Grid
                                        item
                                        xs={12}
                                        sm={label === 'Description' ? 12 : 6}
                                        key={field}
                                    >
                                        {
                                            ['toBePaidWithRent', 'fixedPayment'].includes(field) ?
                                                <FormControlLabel
                                                    control={<Checkbox onChange={(e) => {
                                                        setState({ ...state, [field]: e.target.checked })
                                                    }} checked={(state as any)[field]} name={field} color="primary" />}
                                                    label={label}
                                                /> :
                                                <TextField
                                                    autoComplete="given-name"
                                                    name={field}
                                                    required={!['amountPerMonth', 'costPerUnit'].includes(field)}
                                                    fullWidth
                                                    label={label}
                                                    type={type || 'text'}
                                                    autoFocus
                                                    error={Boolean(error)}
                                                    helperText={error}
                                                    maxRows={10}
                                                    minRows={5}
                                                    multiline={label === 'Description'}
                                                />
                                        }
                                    </Grid>
                            ))
                        }
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={adding}
                    >
                        {`${record?._id ? 'Update' : 'Add'} Utility`}
                    </LoadingButton>
                </Box>
            </Container>
        </Fragment>
    )
}

export default Connect(mapStateToProps)(NewUtility)
