import { createTheme, } from '@mui/material';

// Create a custom theme with the desired primary color
export default createTheme({
    palette: {
        primary: {
            main: '#4B9601', // Replace this with your desired primary color
        },
    },
})

