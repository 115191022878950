import PageTitle from '../Common/PageTitle'
import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Tooltip, Typography } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { theme } from '../../theme'
import { LocationOnOutlined } from '@mui/icons-material'
import KenyaCities from '../../configs/ke.json'
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation } from 'react-router'
import { Apis, uploadToDigitalOceans } from '../../helpers'
import { LoadingButton } from '@mui/lab';
import { Connect, mapDispatchToProps, mapStateToProps } from '../../Redux'
import { PropertyProps } from '../../types/properties'
import { EventsEmit } from '../../configs/events'
import { useNavigate } from 'react-router-dom'
import AlertDialogSlide from '../Common/alert'
import { appName } from '../../configs'
import LocationDialogue from './location'
interface Props {
    setRedux?: (data: any) => void
    properties?: PropertyProps[]
}
interface StateProps {
    logo: string | null
    base64: {
        ext: string
        data: string
    } | null,
    adding: boolean
    location?: string
    loadingLocation: boolean
    cityError: string | null
    nameError: string | null
    showAlert: boolean
    showAddLocation: boolean
}
const NewProperty: FC<Props> = ({
    properties,
    setRedux
}) => {
    const location = useLocation()
    const { item } = location?.state || {}
    const { _id } = item || {}
    const { coordinates } = item?.location || {}

    const [state, setState] = useState<StateProps>({
        logo: item?.logo || null,
        base64: null,
        adding: false,
        location: coordinates ? coordinates[0] + ', ' + coordinates[1] : '',
        loadingLocation: false,
        cityError: null,
        nameError: null,
        showAlert: false,
        showAddLocation: false
    })
    const { adding, logo, base64, loadingLocation, nameError, showAddLocation, showAlert, cityError } = state
    const fileInputRef = useRef(null) as any;
    const navigate = useNavigate()

    //functions
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const obj = Object.fromEntries((formData as any).entries());
        obj.location = (state.location?.split(", ") || []).map(q => parseFloat(q))
        if (!obj.name || !obj.city) {
            let updateObj: any = {
                cityError: "Please enter city where property is located.",
            };
            if (!obj.name)
                updateObj = { nameError: "Please enter name for property." };

            setState({
                ...state,
                ...updateObj,
            });
        } else {
            setState({
                ...state,
                adding: true,
            });

            let base64Images: any = [];
            let newLogo: any;
            if (base64) base64Images.push(base64);
            newLogo = (await uploadToDigitalOceans({
                base64Images,
                _id,
                folder: "logos",
            }))[0]?.image;
            Apis({
                module: "Properties",
                action: "addUpdate",
                logo: newLogo || logo,
                oldLogo: newLogo ? logo : undefined,
                _id,
                ...obj,
                location: {
                    locName: obj.city,
                    coordinates: obj.location,
                }
            })
                .then((resp: any) => {
                    const { newProperty, updatedProperty, message } = resp
                    if (newProperty || updatedProperty) {
                        let updateObj: any = {
                            selectedProperty: newProperty || updatedProperty,
                        };
                        if (updatedProperty) {
                            let Properties = [...properties as PropertyProps[]];
                            const projectIndex = (properties as PropertyProps[]).findIndex((q) => q._id === _id);
                            Properties[projectIndex] = updatedProperty;
                            updateObj = { ...updateObj, properties: Properties };
                        } else
                            updateObj = {
                                ...updateObj,
                                properties: [...properties as PropertyProps[], newProperty],
                            };
                        if (setRedux)
                            setRedux(updateObj);
                    }
                    EventsEmit("app", {
                        info: {
                            message,
                            status: "success",
                        },
                    });
                    navigate("/properties");
                })
                .catch((err) => {
                    console.log("Error creating property", err)
                    setState({
                        ...state,
                        adding: false,
                    });
                });
        }
    };

    const getLocation = () => {
        if (navigator.geolocation) {
            setState({
                ...state, loadingLocation: true
            })
            navigator.geolocation.getCurrentPosition(
                ({ coords: { latitude, longitude } }) => {
                    setState({
                        ...state,
                        location: latitude + ', ' + longitude,
                        loadingLocation: false
                    });

                },
                error => {
                    setState({
                        ...state,
                        loadingLocation: false,
                        showAlert: true
                    })
                }
            )
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };
    const handleLogoChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();


            reader.onloadend = () => {
                const base64Image = reader.result as string;
                const data = base64Image.split(",")[1];
                const ext = base64Image.split("image/")[1].split(";")[0];
                setState({ ...state, base64: { ext, data } });
            };

            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (!coordinates)
            getLocation()

        return () => {

        }
    }, [])


    return (
        <Fragment  >
            <PageTitle
                title={`${_id ? 'Update' : 'Add'} Property`}
                backBtn
            />
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 10, ml: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Button sx={{
                                height: 100,
                                width: 100,
                                border: '1px dashed ' + theme.lightTheme.palette.primary.main,
                                borderRadius: 3,
                                textTransform: 'none'
                            }}
                                onClick={() => {
                                    if (fileInputRef.current){
                                        fileInputRef.current.click();
                                    }
                                }}
                            >
                                {
                                    logo || base64 ? (
                                        <img
                                            alt={""}
                                            src={
                                                base64
                                                    ? `data:image/${base64.ext};base64,${base64.data}`
                                                    : (logo as string)
                                            }
                                            style={{
                                                height: 100,
                                                width: 100,
                                                borderRadius: 3,
                                                objectFit: 'cover'
                                            }}
                                        />
                                    ) : (
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                mt: 0,
                                            }}
                                        >
                                            Upload
                                        </Typography>
                                    )
                                }
                                <input
                                    placeholder="just a placeholder"
                                    ref={fileInputRef}
                                    style={{
                                        height: 0,
                                        width: 0,
                                        opacity: 0,
                                    }}
                                    type={"file"}
                                    accept="image/*"  
                                    onChange={handleLogoChange}
                                />
                            </Button>
                            <Typography sx={{ ml: 2, fontSize: 16 }}>{logo ? 'Update' : 'Add'} Logo</Typography>
                        </Box>
                    </Grid>
                    <Grid container spacing={2} sx={{ mt: 3, ml: 0 }}>
                        <Grid item xs={5}  >
                            <TextField
                                fullWidth
                                defaultValue={item?.name}
                                required
                                label={'Name'}
                                name={'name'}
                                onFocus={() => setState({ ...state, nameError: null })}
                                error={Boolean(nameError)}
                                helperText={nameError}
                            />
                        </Grid>
                        <Grid item sm={5}  >
                            <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                defaultValue={item?.location?.locName}
                                options={KenyaCities.map((option) => option).sort((a, b) => a > b ? 1 : a < b ? -1 : 0)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="City"
                                        name={'city'}
                                        onFocus={() => setState({ ...state, cityError: null })}
                                        required
                                        InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                        }}
                                        error={Boolean(cityError)}
                                        helperText={cityError}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ mb: 7, mt: 3, ml: 0 }}>
                        <Grid item sm={5} >
                            <TextField
                                fullWidth
                                defaultValue={item?.placeName}
                                label={'Name of place'}
                                name={'placeName'}
                                onFocus={() => setState({ ...state, nameError: null })}
                                error={Boolean(nameError)}
                                helperText={nameError}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                label={'Location'}
                                name={'location'}
                                disabled
                                defaultValue={state.location}
                                value={state.location}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <Tooltip title={"Click to load current location"}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={getLocation}
                                                edge="end"
                                                disabled={loadingLocation}
                                            >
                                                {
                                                    loadingLocation ?
                                                        <CircularProgress size={20} /> :
                                                        <LocationOnOutlined />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                        <Button
                                            variant={'contained'}
                                            sx={{
                                                textTransform: 'none',
                                                ml: 2
                                            }}
                                            onClick={() => setState({
                                                ...state,
                                                showAddLocation: true
                                            })}
                                        >Enter Link</Button>
                                    </InputAdornment>
                                }
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ ml: 0 }}  >
                        <Grid item sm={5} >
                            <TextField
                                fullWidth
                                defaultValue={item?.description}
                                label={'Description'}
                                name={'description'}
                                placeholder={'You can describe your property in bullet form.'}
                                multiline
                                minRows={3}
                                maxRows={8}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 5 }}>
                        <Grid item xs={3}>
                            <LoadingButton
                                disabled={adding}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                loading={adding}
                            >
                                {`${_id ? 'Update' : 'Add'} Property`}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                </Grid>
            </Box>
            {
                showAlert ?
                    <AlertDialogSlide
                        title='Problem getting you location'
                        message={'Please enable location in your browser and grant ' + appName + ' access to location'}
                        close={() => setState({ ...state, showAlert: false })}
                    /> : null}
            {
                showAddLocation ?
                    <LocationDialogue 
                    Title={"Location coordinates"}
                    dlgText={" Location coordinates will help potential tenants locate your property. You can paste location link for example from Whatsapp."}
                        close={() => setState({ ...state, showAddLocation: false })}
                        onGetLocation={
                            (location) => {
                                setState({
                                    ...state,
                                    location,
                                    showAddLocation: false
                                })
                                EventsEmit('app', {
                                    info: {
                                        message: "Location found successfully.",
                                        status: 'success'
                                    }
                                })
                            }
                        }
                    /> :
                    <Fragment />

            }
        </Fragment>
    )
}

export default Connect(mapStateToProps, mapDispatchToProps)(NewProperty)
