import { Box, Button, CircularProgress, IconButton, InputBase, Toolbar, Tooltip } from "@mui/material"
import { useNavigate } from "react-router";
import { styled, alpha } from '@mui/material/styles';
import { Search as SearchIcon, Refresh, Add, Cancel } from '@mui/icons-material'
import { FC, Fragment, useState } from "react";
import ColumnsFilter from "./ColumnsFilter";
import { Connect, mapStateToProps } from "../../Redux";
import FilterComponent from "./filter";
import SortComponent from "./sort";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));
export interface FilterParamProps {
    label: string
    field: string
    options?: string[]
    selected: string[] |boolean
    type: string
}

interface Props {
    addTitle?: string
    addPage?: string
    columns?: any[]
    columnsFilter?: string
    unitNames?: string[]
    refreshing?: boolean
    noSearch?: boolean
    noAddButton?: boolean
    onAddClick?: () => void
    onRefresh?: () => void
    onFilter?: (filter:any) => void
    onSearch?: (newValue: string) => void
    filterOptions?:FilterParamProps[]
}
interface StateProps {
    inputValue: string
}
const ToolBar: FC<Props> = ({
    addTitle,
    addPage,
    onAddClick,
    columns,
    columnsFilter,
    unitNames,
    refreshing,
    noSearch,
    noAddButton,
    filterOptions,
    onRefresh,
    onFilter,
    onSearch
}) => {
    const navigate = useNavigate()
    const [state, setState] = useState<StateProps>({
        inputValue: ''
    })
    const { inputValue } = state

    const actionBtns = [
        filterOptions?.length?{ label: 'Filter', icon: <FilterComponent filterOptions={filterOptions as FilterParamProps[]} onFilter={onFilter} /> }:null,
        // { label: 'Sort', icon: <SortComponent /> },
        columns ? { label: 'View Columns', icon: <ColumnsFilter columnsFilter={columnsFilter} columns={columns as any} /> } : null,
        { label: 'Refresh', icon: refreshing ? <CircularProgress size={20} /> : <Refresh />, onClick: onRefresh },
    ].filter(q => Boolean(q))

    //functions
    const onValueChange = (newValue: string) => {
        setState({
            ...state,
            inputValue: newValue
        })
        if (onSearch)
            onSearch(newValue)
    }
    // const handleRefresh = useCallback(() => {
    //     console.log("refreshing");
    //     onRefresh();
    //   }, []);
    return (
        <Toolbar sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            mr: 5
        }}>
            {
                noAddButton ?
                    <Box /> :
                    <Button
                        sx={{ textTransform: 'none', fontSize: 16 }}
                        onClick={addPage ? (() => navigate(addPage, { state: { unitNames } })) : onAddClick}
                    >
                        <Add />
                        {addTitle}
                    </Button>
            }
            <Box sx={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <Box sx={{ width: 400, borderBottom: noSearch ? 'transparent' : '1px solid rgba(0, 0, 0, 0.1)' }}>
                    {
                        noSearch ?
                            <Fragment /> :
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon sx={{ color: '#959595', fontSize: 20 }} />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{
                                        'aria-label': 'search',
                                    }}
                                    value={inputValue}
                                    onChange={(c) => onValueChange(c.target.value)}
                                    endAdornment={
                                        inputValue && (
                                            <IconButton
                                                onClick={() => {
                                                    onValueChange('')
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 0
                                                }}
                                            >
                                                <Cancel sx={{ color: '#CBCBCB', fontSize: 20 }} />
                                            </IconButton>
                                        )
                                    }
                                />
                            </Search>
                    }
                </Box>
                {
                    actionBtns.map(({ label, icon, onClick }: any) => (
                        label !== 'Refresh' ?
                            <Fragment key={label}>
                                {icon}
                            </Fragment>
                            :
                            <Tooltip key={label} title={label}>
                                {

                                    <IconButton
                                        disabled={label === 'Refresh' && refreshing}
                                        onClick={onClick}
                                    >
                                        {icon}
                                    </IconButton>
                                }
                            </Tooltip>
                    )
                    )
                }


            </Box>
        </Toolbar>
    )
}

export default Connect(mapStateToProps)(ToolBar)