import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface StateProps {
    locationError: string | null
}
interface Props {
    close: () => void
    onGetLocation: (location: string) => void
    Title:string,
    dlgText:string
}
const LocationDialogue: React.FC<Props> = ({
    close,
    onGetLocation,
    Title,
    dlgText
}) => {
    const [state, setState] = React.useState<StateProps>({
        locationError: null
    })
    const { locationError } = state
    const getLatLngFromUrl = (url: string) => {
        try {
            var queryString = url.split('?')[1];
            var queryParams = queryString.split('&');

            var latitude, longitude;

            queryParams.forEach(function (param) {
                var pair = param.split('=');
                if (pair[0] === 'q') {
                    var coords = pair[1].split(',');
                    latitude = parseFloat(coords[0]);
                    longitude = parseFloat(coords[1]);
                }
            });
            // Return latitude and longitude as an object
            return {
                latitude: latitude,
                longitude: longitude
            };
        }
        catch (e) {
            setState({
                ...state,
                locationError: "Invalid link. Please paste a valid location link."
            })
        }
    }
    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        const location = getLatLngFromUrl(formJson.location)
        if(location?.latitude)
        onGetLocation(location?.latitude + ', ' + location?.longitude)
    }
    return (
        <React.Fragment>

            <Dialog
                open

                PaperProps={{
                    component: 'form',
                    onSubmit
                }}
            >
                <DialogTitle>{Title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       { dlgText}
                    </DialogContentText>
                    <TextField
                        sx={{ mt: 5 }}
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="location"
                        label={"Paste Url"}
                        fullWidth
                        variant="standard"
                        error={Boolean(locationError)}
                        helperText={locationError}
                        onFocus={() => setState({ ...state, locationError: null })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close}>Cancel</Button>
                    <Button type="submit">Get Location</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default LocationDialogue