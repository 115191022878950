import './App.css';
import Navigation from './components/Navigation';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { Toaster, toast } from 'react-hot-toast';
import { useEffect } from 'react';
import { Events } from './configs/events';

const App = () => {
  useEffect(() => {
    const eventListener = Events.addEventListener('app', ({
      info: {
        message,
        status,
        options
      }
    }) => {
      (toast as any)[status](message, options || { position: 'top-center' })
    })
    return () => {
      Events.removeEventListener(eventListener as string)
    }
  }, [])
  return (
    <ThemeProvider theme={theme['lightTheme']}>
      <Navigation />
      <Toaster />
    </ThemeProvider>
  );
}

export default App;
