import PageTitle from '../Common/PageTitle'
import { Autocomplete, Box, CircularProgress, Container, Grid, InputAdornment, TextField } from '@mui/material'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { InputProps } from '../../types'
import { Apis } from '../../helpers'
import { Connect, mapStateToProps } from '../../Redux'
import { PropertyProps, UnitsProps } from '../../types/properties'
import { LoadingButton } from '@mui/lab';
import { EventsEmit } from '../../configs/events'
import { useNavigate } from 'react-router-dom'
interface Props {
    selectedProperty?: PropertyProps
}
interface StateProps {
    inputs: InputProps[]
    adding: boolean
    unitNames: UnitsProps[]
    loadingUnits: boolean
}
const NewUnit: FC<Props> = ({
    selectedProperty
}) => {
    const location = useLocation()
    const { unit } = location?.state || {}

    const initialInputs: InputProps[] = [
        { label: 'Name', value: '', field: 'name' },
        { label: 'Number of Units', value: '', type: 'number', field: 'numberOfUnits' },
        { label: 'Number of Bedrooms', value: '', type: 'number', field: 'numberOfBedrooms' },
        { label: 'Number of Bathrooms', value: '', type: 'number', field: 'numberOfBathrooms' },
        { label: 'Rent Price Per Month (Ksh)', value: '', type: 'number', field: 'pricePerMonth' },
        { label: 'Deposit (Ksh)', value: '', type: 'number', field: 'deposit' },
        { label: 'Description', value: '', field: 'description' }
    ]
    const [state, setState] = useState<StateProps>({
        inputs: initialInputs,
        adding: false,
        unitNames: [],
        loadingUnits: false
    })
    const { inputs, adding, unitNames, loadingUnits } = state
    const fomrRef = useRef(null)
    const navigate = useNavigate()
    const changeInput = (input: string, obj: any) => {
        const Inputs = [...inputs]
        const inputIndex = inputs.findIndex(q => q.field === input)
        Inputs[inputIndex] = { ...Inputs[inputIndex], ...obj }
        setState({
            ...state,
            inputs: Inputs,
            adding: false
        })
    }
    //functions
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const obj: any = {}
        data.forEach((v, k) => obj[k] = v)
        setState({ ...state, adding: true })
        Apis({
            module: 'Properties',
            action: 'addUpdateUnit',
            propertId: selectedProperty?._id,
            unitId: unit?._id,
            ...obj
        }).then(({ message }: any) => {
            //reset inputs
            //show toast message
            getUnitCategories()
            EventsEmit('app', {
                info: {
                    message,
                    status: 'success'
                },
            })
            if (unit?._id)
                navigate(-1)
        }).catch((e) => {
            setState({ ...state, adding: false })
        })
    };
    const getUnitCategories = () => {
        Apis({
            module: 'Properties',
            action: 'getUnitCategories',
            propertId: selectedProperty?._id
        }).then((resp: any) => {
            setState({
                ...state,
                unitNames: resp,
                loadingUnits: false,
                inputs: initialInputs
            })
        }).catch((err: any) => {
            console.log(err)
            setState({
                ...state,
                loadingUnits: false,
                adding: false,
                inputs: initialInputs
            })
        })
    }
    const inputProps = (value: any) => unit ? {} : { value }
    useEffect(() => {
        getUnitCategories()
        return () => {

        }
    }, [])

    return (
        <Fragment  >
            <PageTitle
                title={`${unit?._id ? 'Update' : 'Add'} Unit`}
                backBtn
            />
            <Container component="main" maxWidth="sm" sx={{
                pt: 10,
                ml: 10
            }}>
                <Box component="form" ref={fomrRef} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <Grid container spacing={3}>
                        {
                            inputs.map(({ label, type, value, field, error }, i: number) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={label === 'Description' ? 12 : 6}
                                    key={field}
                                >
                                    {
                                        field === 'name' ?
                                            <Autocomplete
                                                freeSolo
                                                id="free-solo-2-demo"
                                                defaultValue={unit && unit[field] || ''}
                                                disableClearable
                                                options={unitNames.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0).map(q => q.name)}
                                                onChange={(_, newValue) => {
                                                    const selectedUnit = unitNames.find(q => q.name === newValue)
                                                    const Inputs = [...inputs]
                                                    const bedroomsIndex = inputs.findIndex(q => q.field === 'numberOfBedrooms')
                                                    const bathroomsIndex = inputs.findIndex(q => q.field === 'numberOfBathrooms')
                                                    Inputs[bedroomsIndex] = { ...Inputs[bedroomsIndex], value: selectedUnit?.numberOfBedrooms as any }
                                                    Inputs[bathroomsIndex] = { ...Inputs[bathroomsIndex], value: selectedUnit?.numberOfBathrooms as any }
                                                    setState({
                                                        ...state,
                                                        inputs: Inputs
                                                    })
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={label + ' '}
                                                        name={'name'}
                                                        required
                                                        error={Boolean(error)}
                                                        helperText={error}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            type: 'search',
                                                            endAdornment: loadingUnits ? <InputAdornment position="end">
                                                                <CircularProgress size={25} />
                                                            </InputAdornment> :
                                                                <Fragment />
                                                        }}
                                                    />
                                                )}
                                            /> :

                                            <TextField
                                                autoComplete="given-name"
                                                name={field}
                                                required={label !== 'Description'}
                                                fullWidth
                                                label={label}
                                                type={type || 'text'}
                                                autoFocus
                                                defaultValue={unit && unit[field] || ''}
                                                error={Boolean(error)}
                                                helperText={error}
                                                maxRows={10}
                                                minRows={5}
                                                {...inputProps(value)}
                                                multiline={label === 'Description'}
                                                onChange={(c) => changeInput(field, { value: c.target.value })}
                                                inputProps={{ min: 0 }}
                                            />
                                    }
                                </Grid>
                            ))
                        }
                    </Grid>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                        loading={adding}
                    >
                        {`${unit?._id ? 'Update' : 'Add'} Unit`}
                    </LoadingButton>
                </Box>
            </Container>

        </Fragment>
    )
}

export default Connect(mapStateToProps)(NewUnit)
