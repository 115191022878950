import { Avatar, Box, Button, Divider, List, ListItem, ListItemAvatar, ListItemText, Rating, TextField, Typography } from "@mui/material"
import React, { FC, Fragment, useEffect, useState } from "react"
import Toolbar from "../../Common/Toolbar"
import { PropertyProps } from "../../../types/properties"
import NoData from "../../Common/NoData"
import { Apis } from "../../../helpers"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TenantProps } from "../../../types/tenants"
import { Connect, mapStateToProps } from "../../../Redux"
import { UserProps } from "../../../types/user"
import { LoadingButton } from "@mui/lab"
import { EventsEmit } from "../../../configs/events"
import moment from "moment"

interface Props {
    tenant: TenantProps
    User?: UserProps
    selectedProperty?: PropertyProps
    getUserRating:()=>void
}
interface ReviewProps {
    _id: string
    by: PropertyProps
    rating: number
    text: string,
    date: Date
}
interface StateProps {
    data: ReviewProps[]
    loading: boolean
    showDialog: boolean
    refreshing: boolean
    rate: number
    submitting: boolean
}
export const labels: { [index: string]: string } = {
    1: 'Cannot Recommend',
    1.5: 'Poor+',
    2: 'Poor',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};
const Reviews: FC<Props> = ({
    tenant,
    User,
    selectedProperty,
    getUserRating
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        loading: true,
        showDialog: false,
        refreshing: false,
        rate: 0,
        submitting: false
    })
    const { data, loading, showDialog, refreshing, rate, submitting } = state

    const getReviews = ({ refreshing }: { refreshing?: boolean }) => {
        if (refreshing)
            setState({ ...state, refreshing: true })
        Apis({
            module: 'Reviews',
            action: 'getReviews',
            reviewForId: tenant._id
        }).then((data: any) => {
            setState({
                ...state,
                loading: false,
                data,
                showDialog: false,
                submitting: false
            })
        }).catch(() => {
            setState({
                ...state,
                loading: false,
                showDialog: false,
                submitting: false
            })
        })
    },
        closeOpenDialog = (open?: boolean) => {
            setState({ ...state, showDialog: open as boolean })
        },
        onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            setState({
                ...state,
                submitting: true
            })
            Apis({
                module: 'Reviews',
                action: 'addReview',
                ...formJson,
                rating: formJson.rating || 5,
                byId: User?._id,
                propertId: selectedProperty?._id,
                tenantId: tenant._id
            }).then(({ message }: any) => {
                setState({
                    ...state,
                    showDialog: false,
                    submitting: false
                })
                getUserRating()
                EventsEmit('app',
                    {
                        info: {
                            message,
                            status: 'success'
                        }
                    })
                getReviews({})
            }).catch((err) => {
                console.log(err)
                EventsEmit('app',
                    {
                        info: {
                            message: 'Review not sent.',
                            status: 'error'
                        }
                    })
                setState({
                    ...state,
                    submitting: false
                })
            })
        }
    useEffect(() => {
        getReviews({})
        return () => {

        }
    }, [])

    return (
        <Box sx={{ height: '70vh', mt: 5 }}>
            <Typography sx={{ fontWeight: 'bold' }}>Reviews</Typography>
            {!loading && data?.length ? <Toolbar
                addTitle="Add Review"
                noSearch
                onAddClick={() => closeOpenDialog(true)}
                onRefresh={() => getReviews({ refreshing: true })}
                refreshing={refreshing}
            /> : null}
            {
                !data?.length || loading ?
                    <NoData
                        createMessage={`Add Review`}
                        onCreate={() => closeOpenDialog(true)}
                        loading={loading}
                    /> :
                    <List sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
                        {
                            data.map(({ _id, by, rating, text, date }, i) => (
                                <Fragment key={_id}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar alt={by.name} src={by.logo} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={by.name}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        sx={{ display: 'inline', fontSize: 13 }}
                                                        component="span"
                                                        variant="body2"
                                                        color="text.primary"
                                                    >
                                                        {moment(date).format("DD/MM/YYYY: HH:mm")}
                                                    </Typography>
                                                    <Box sx={{ mb: 2 }}>
                                                        <Rating readOnly name="size-small" value={rating} />
                                                        <Box >{labels[rating]}</Box>
                                                    </Box>
                                                    <Typography>  {text}</Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    {i !== data.length - 1 ? <Divider sx={{ mb: 2 }} variant="inset" component="li" /> : <Fragment />}
                                </Fragment>
                            ))
                        }

                    </List>
            }
            <Dialog
                open={showDialog}
                onClose={() => closeOpenDialog()}
                PaperProps={{
                    component: 'form',
                    onSubmit,
                }}
            >
                <DialogTitle>Rate</DialogTitle>
                <DialogContent sx={{ width: 400 }}>
                    <DialogContentText>
                        How would you rate {tenant.name}?
                    </DialogContentText>
                    <Rating
                        name="rating"
                        value={rate}
                        onChange={(_, newValue) => {
                            setState({
                                ...state,
                                rate: newValue || 0
                            })
                        }}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        name="text"
                        label="Message"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={6}
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color="error"
                        sx={{ textTransform: 'none' }}
                        onClick={() => closeOpenDialog()}
                    >Cancel</Button>
                    <LoadingButton
                        sx={{ textTransform: 'none' }}
                        type="submit"
                        loading={submitting}
                    >Submit</LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Connect(mapStateToProps)(Reviews)