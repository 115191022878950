import { FC, Fragment } from 'react'
import { Typography, Box, Button, CircularProgress } from '@mui/material'
import { Add } from '@mui/icons-material'

interface Props {
    message?: string
    createMessage?: string
    children?: any
    onCreate?: () => void
    loading?: boolean
    searchVal?: string
}

const NoData: FC<Props> = ({
    children,
    message,
    createMessage,
    loading,
    searchVal,
    onCreate
}) => {
    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {
                loading ?
                    <CircularProgress /> :
                    <Fragment>

                        <Typography
                            sx={{
                                color: '#ABABAB',
                                fontSize: 17
                            }}
                        >{searchVal ? 'No search result found' : (message || 'No Data')}</Typography>
                        {children}
                        {
                            createMessage ?

                                <Button
                                    variant={'contained'}
                                    sx={{ textTransform: 'none', marginTop: 3 }}
                                    onClick={onCreate}
                                >
                                    <Add />
                                    {createMessage}
                                </Button> :
                                <Fragment />
                        }
                    </Fragment>
            }
        </Box>
    )
}

export default NoData