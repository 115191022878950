import PageTitle from "../Common/PageTitle"
import ToolBar from "../Common/Toolbar"
import { TenantProps } from "../../types/tenants"
import { FC, Fragment, useEffect, useState } from "react"
import columns from "./columns"
import { Avatar, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import MenuListPop from "../Common/menu"
import { AddCardOutlined, DeleteOutlineOutlined, EditOutlined, EmailOutlined, RemoveRedEyeOutlined } from "@mui/icons-material"
import NoData from "../Common/NoData"
import { useNavigate } from "react-router-dom"
import { Apis, getFormattedPhone, numberWithCommas } from "../../helpers"
import { PropertyProps } from "../../types/properties"
import { Connect, mapStateToProps } from "../../Redux"
import DeleteModal from '../Common/deletePrompt'
import { TABLE_HEIGHT } from "../../configs"

interface StateProps {
    data: TenantProps[],
    showDeleteModal: null | TenantProps
    loading: boolean
    refreshing: boolean
    searchVal: string
}
interface Props {
    tablesColumnsFilters?: {
        tenants: string[]
    }
    selectedProperty?: PropertyProps
}
const Tenants: FC<Props> = ({
    tablesColumnsFilters,
    selectedProperty
}) => {
    const [state, setState] = useState<StateProps>({
        data: [],
        showDeleteModal: null,
        loading: true,
        refreshing: false,
        searchVal: ''
    })
    const navigate = useNavigate()

    const menuList = [
        { label: 'Edit', icon: <EditOutlined /> },
        { label: 'View', icon: <RemoveRedEyeOutlined /> },
        { label: 'Message', icon: <EmailOutlined /> },
        { label: 'Add Payment', icon: <AddCardOutlined /> },
        { label: 'Delete', icon: <DeleteOutlineOutlined /> }
    ]
    const { loading, showDeleteModal, refreshing, searchVal } = state
    let { data } = state
    const DisplayColums = columns.filter(col => tablesColumnsFilters?.tenants.includes(col.name))
    data = searchVal ? data.filter((q) => q.name.toLowerCase().includes(searchVal.toLowerCase())) : data
    const getPropertyTenants = ({
        refreshing
    }: any) => {
        if (refreshing)
            setState({ ...state, refreshing: true })
        Apis({
            module: 'Properties',
            action: 'getPropertyTenants',
            _id: selectedProperty?._id
        }).then((data: any) => {
            setState({
                ...state,
                data,
                loading: false,
                refreshing: false
            })
        }).catch(() => {
            setState({
                ...state,
                loading: false,
                refreshing: false
            })
        })
    }
    useEffect(() => {
        getPropertyTenants({})
        // eslint-disable-next-line 
    }, [])
    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            <PageTitle title={"Tenants"} />
            {!loading && data.length || searchVal ?
                <ToolBar
                    columnsFilter={'tenants'}
                    columns={columns}
                    addTitle={'Add Tenant'}
                    addPage={'/new_tenant'}
                    refreshing={refreshing}
                    onRefresh={() => getPropertyTenants({ refreshing: true })}
                    onSearch={(searchVal) => setState({ ...state, searchVal })}
                /> : null}
            {
                !data?.length || loading ?
                    <NoData
                        message={`You don't have any tenant.`}
                        searchVal={searchVal}
                        createMessage={searchVal?undefined:`Add New Tenant`}
                        onCreate={ () => navigate('/new_tenant')}
                        loading={loading}
                    /> :
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: TABLE_HEIGHT }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {
                                            DisplayColums.map(({ label }) => (
                                                <TableCell sx={{ fontWeight: 'bold' }} key={label} /*align={column.align}*/>
                                                    {label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.map((datam, i) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={datam.name}>
                                                    {
                                                        DisplayColums.map(({ label, name, isNumber }, x) => {
                                                            const value = name === 'unitName' ?

                                                                datam?.unit?.name :
                                                                name === 'phone' ? (datam.phone ?getFormattedPhone(datam.phone ) : 'None') :
                                                                    (datam as any)[name] || 'None'
                                                            return (
                                                                <TableCell key={label + '' + datam.name} /*align={column.align}*/>
                                                                    <Box
                                                                        sx={{
                                                                            flexDirection: 'row',
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            name === 'name' ?
                                                                                <Avatar sx={{ mr: 1 }} src={datam.image} alt="" /> :
                                                                                null
                                                                        }
                                                                        {
                                                                            name === 'action' ?
                                                                                <MenuListPop
                                                                                    onSelect={option => {
                                                                                        if (option === 'Edit')
                                                                                            navigate('/new_tenant', { state: { record: datam } })
                                                                                        else if (option === 'Add Payment')
                                                                                            navigate('/new_payment', {
                                                                                                state: {
                                                                                                    record: {
                                                                                                        unit: datam.unit,
                                                                                                        unitLabel: {
                                                                                                            _id: datam.labelId,
                                                                                                            unitLabel: datam.unitLabel
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            })
                                                                                        else if (option === 'View')
                                                                                            navigate('/tenant', {
                                                                                                state: {
                                                                                                    user: datam
                                                                                                }
                                                                                            })
                                                                                        else if (option === 'Message')
                                                                                            navigate('/new_email', {
                                                                                                state: {
                                                                                                    user: datam,
                                                                                                }
                                                                                            })
                                                                                        else if (option === 'Delete')
                                                                                            setState({
                                                                                                ...state,
                                                                                                showDeleteModal: datam
                                                                                            })
                                                                                    }}
                                                                                    menuList={menuList}
                                                                                /> :
                                                                                <span
                                                                                    style={{
                                                                                        color: value < 0 ? 'red' : value > 0 && name === 'balance' ? 'green' : '#000'
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        isNumber ? numberWithCommas(Math.abs(value)) :
                                                                                            value
                                                                                    }
                                                                                </span>
                                                                        }
                                                                    </Box>
                                                                </TableCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>
            }
            {
                showDeleteModal ?
                    <DeleteModal
                        item={showDeleteModal}
                        close={() => setState({ ...state, showDeleteModal: null })}
                        onDelete={() => {
                            const itemIndex = data?.findIndex(q => q.labelId === showDeleteModal.labelId) as number
                            const Items = [...data as TenantProps[]]
                            Items?.splice(itemIndex, 1)
                            setState({
                                ...state,
                                showDeleteModal: null,
                                data: Items
                            })

                        }}
                        page='Tenant'
                        module={'Properties'}
                        itemName={showDeleteModal.name}
                        unitId={showDeleteModal.unit._id}
                        unitLabelId={showDeleteModal.labelId}
                    /> :
                    <Fragment />

            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Tenants)