import { Avatar, Box, Rating, Typography } from "@mui/material"
import { UserProps } from "../../../types/user"
import { FC, Fragment, useEffect, useState } from "react"
import { TenantProps } from "../../../types/tenants"
import { parsePhoneNumber } from "libphonenumber-js"
import Reviews, { labels } from "./reviews"
import { Apis } from "../../../helpers"
interface Props {
    user: TenantProps
}
interface StateProps {
    rating: number
}

const customRound = (number:number) =>{
    if (Number.isInteger(number)) {
        return number;
    } else {
        return Math.round(number);
    }
}
const General: FC<Props> = ({
    user
}) => {
    const [state, setState] = useState<StateProps>({
        rating: 0
    })
    const { rating } = state
    const Labels = {
        Email: user.email,
        Phone: user.phone ? parsePhoneNumber(user.phone).format('INTERNATIONAL') : 'None'
    }
    const getUserRating = () => {
        Apis({
            module: 'Reviews',
            action: 'getRating',
            reviewForId: user._id
        }).then((resp: any) => {
            setState({
                ...state,
                rating: resp
            })
        }).catch(() => {

        })
    }
    useEffect(() => {
        getUserRating()
        return () => {

        }
    }, [])

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mb: 5
            }}>
                <Avatar src={user.image} sx={{ mr: 1, height: 70, width: 70 }} />
                <Box>
                    <Typography>{user.name}</Typography>
                    <Rating readOnly size={"large"} value={rating} />
                    <Box >{labels[customRound(rating)]}</Box>
                </Box>
            </Box>
            {
                Object.keys(Labels).map((key: any) => (
                    <Box sx={{
                        flexDirection: 'row',
                        display: 'flex',
                        mt: 2
                    }} key={key}>
                        <Typography sx={{ fontWeight: 'bold', mr: 5 }}>{key}: </Typography>
                        <Typography>{(Labels as any)[key] || 'None'}</Typography>
                    </Box>
                ))
            }
            <Reviews tenant={user} getUserRating={getUserRating} /> : <Fragment />
        </Box>
    )
}

export default General