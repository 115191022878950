import { Box, Grid, Typography } from "@mui/material"
import PageTitle from "../Common/PageTitle"
import { Apis, numberWithCommas } from "../../helpers"
import { Pie, TinyLineChart } from "./chart"
import { theme } from "../../theme"
import { FC, Fragment, useEffect, useState } from "react"
import { Connect, mapStateToProps } from "../../Redux"
import { PropertyProps } from "../../types/properties"
import NoData from "../Common/NoData"
import { useNavigate } from "react-router-dom"

interface StatProps {
    label: string
    value: number
    bgColor: string
    more?: {
        percentage: number
        label: string
        value: number
        money: boolean
    }
}
interface Props {
    selectedProperty?: PropertyProps
}
interface StateProps {
    loading: boolean,
    firstRowItems: StatProps[],
    secondRowItems: StatProps[]
}
const Dashboard: FC<Props> = ({
    selectedProperty
}) => {
    const [state, setState] = useState<StateProps>({
        firstRowItems: [],
        secondRowItems: [],
        loading: true
    })
    const { firstRowItems, secondRowItems, loading } = state
    const navigate = useNavigate()
    const getPropertyData = () => {
        Apis({
            module: 'Properties',
            action: 'getPropertyData',
            propertId: selectedProperty?._id,
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear()
        }).then((resp: any) => {
            setState({
                ...state,
                ...resp,
                loading: false
            })

        }).catch(() => {
            setState({
                ...state,
                loading: false
            })

        })
    }
    useEffect(() => {
        if (selectedProperty)
            getPropertyData()
        else
            navigate('/properties')
        return () => {

        }
    }, [])

    return (
        <Box sx={{ height: '100%', pr: 5 }}>
            <PageTitle title="Dashboard" />
            {
                loading ?
                    <Box sx={{
                        height: '90vh'
                    }}>
                        <NoData loading={loading} />
                    </Box> :
                    <Fragment>
                        {
                            [firstRowItems, secondRowItems].map((options, i) => (
                                <Grid container key={i} sx={{ pr: 5, mb: 5 }} spacing={3}>
                                    {
                                        options.map(({ label, bgColor, value, money, percentage, more }: any, i) => (
                                            <Grid item xs={3} key={i}>
                                                <Box sx={{
                                                    height: 250,
                                                    width: '100%',
                                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                                    borderRadius: 3,
                                                    bgcolor: bgColor,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}>
                                                    <Typography sx={{ mb: 2, fontSize: 25 }}><span style={{ fontSize: 16 }}>{money ? 'Ksh ' : ''}</span>  {money || percentage ? numberWithCommas(value) : value}{percentage ? '%' : ''}</Typography>
                                                    {
                                                        more ?
                                                            <Typography sx={{ fontWeight: '200', fontSize: 14, mb: 0.5 }}>{more.label}{more.value ? numberWithCommas(more.value) : ''}  {more.percentage && more.value ? `(${numberWithCommas(more.percentage)}%)` : numberWithCommas(more.percentage) + '%'}</Typography> : null
                                                    }
                                                    <Typography sx={{ fontWeight: '200' }}>{label}</Typography>
                                                </Box>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            ))
                        }
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            pr: 15,
                            mt: 30
                        }}>
                            {/* <TinyLineChart /> */}
                            {/* <Box> */}
                            <Pie items={[
                                { value: firstRowItems[3]?.value, label: 'Arrears', color: '#C11121' },
                                { value: firstRowItems[0]?.value, label: 'Collected Revenue', color: theme.lightTheme.palette.primary.main },
                                { value: firstRowItems[2]?.value || 0, label: 'BnBs Revenue', color: '#038E79' }
                            ]} />
                            <Box sx={{ mt: 2 }} />
                            <Pie items={[
                                { value: secondRowItems[0]?.value - secondRowItems[1]?.value, label: 'Vacant units', color: '#A52339' },
                                { value: secondRowItems[1]?.value, label: 'Occupied units', color: '#9DAB14' },
                                { value: secondRowItems[2]?.value, label: 'BnBs', color: '#4F14AB' },
                            ]} />
                            {/* </Box> */}
                        </Box>
                    </Fragment>
            }
        </Box>
    )
}

export default Connect(mapStateToProps)(Dashboard)