import { FilterAltOutlined } from '@mui/icons-material';
import { Box, Button, Checkbox, ClickAwayListener, FormControl, FormControlLabel, FormLabel, Grow, IconButton, MenuItem, Paper, Popper, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { styled } from '@mui/system';
import MultiSelect from './multiSelect';
import { FilterParamProps } from './Toolbar';
interface Props {
  filterOptions: FilterParamProps[]
  onFilter?: (filter: any) => void
}

const FilterComponent: FC<Props> = ({
  filterOptions,
  onFilter
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent | null) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <Stack direction="row" spacing={2}>
      <div >
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <FilterAltOutlined />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 10 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                {/* <ClickAwayListener onClickAway={handleClose}> */}
                <form onSubmit={(e) => {
                  e.preventDefault()
                  const formData = new FormData(e.currentTarget);
                  const formJson = Object.fromEntries((formData as any).entries());
                  Object.keys(formJson).forEach((k) => {
                    let value = formJson[k]
                    if (filterOptions.find(q => q.field === k && q.type === 'multiSelect'))
                      value = (value.split(',')).filter((q: any) => Boolean(q))
                    else if (filterOptions.find(q => q.field === k && q.type === 'boolean'))
                      value = Boolean(value)
                    formJson[k] = value
                  })
                  if (onFilter) {
                    onFilter(formJson)
                    handleClose(null)
                  }
                }}>
                  <Box sx={{
                    width: 350,
                    p: 2
                  }}>
                    <Typography sx={{
                      fontWeight: 'bold',
                      fontSize: 17
                    }}>Filter</Typography>
                    {
                      filterOptions.map((filter, i) => (
                        filter.type === 'multiSelect' ?
                          <MultiSelect key={filter.label} filterParam={filter} /> :
                          filter.type === 'radio' ?
                            <FormControl  sx={{ ml: 1 }} key={filter.label}>
                              <FormLabel id="demo-radio-buttons-group-label">{filter.label}</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={filter.selected}
                                name={filter.field}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row'
                                }}
                              >
                                {
                                  filter.options?.map((option) => (
                                    <FormControlLabel
                                      key={option}
                                      value={option}
                                      control={<Radio />} label={option} />
                                  ))
                                }
                              </RadioGroup>
                            </FormControl> :
                            filter.type === 'boolean' ?
                              <FormControlLabel
                                key={filter.label}
                                sx={{ ml: 1 }}
                                name={filter.field}
                                control={<Checkbox
                                  onChange={(e) => {
                                    // setState({ ...state, [field]: e.target.checked })
                                  }}
                                  checked={filter.selected as boolean}
                                  color="primary" />}
                                label={filter.label}
                              /> :
                              <Fragment key={filter.label} />
                      ))
                    }
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      pr: 1,
                      mt: 10
                    }}>
                      <Button onClick={handleClose} color={'error'}>Cancel</Button>
                      <Button
                        sx={{
                          textTransform: 'none'
                        }}
                        variant={'contained'}
                        type={'submit'}
                      >Apply Filter</Button>
                    </Box>
                  </Box>
                </form>
                {/* </ClickAwayListener> */}
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>

  )
}

export default FilterComponent