const columns = [
    {
        label: 'Date',
        name: 'date',
        isDate: true
    },
    {
        label: 'Unit',
        field: 'name',
        name: 'unit'
    },
    {
        label: 'Tenant',
        field: 'name',
        name: 'tenant'
    },
    {
        label: 'Unit Label / House No.',
        field: 'unitLabel',
        name: 'unitLabel'
    },

    {
        label: 'Parent Invoice',
        name: 'parentInvoice',
        field: 'title'
    },
    {
        label: 'Title',
        name: 'title'
    },
    {
        label: 'Description',
        name: 'description'
    },
    {
        label: 'Amount (Kes)',
        name: 'amount',
        isNumber: true
    },
    {
        label: 'Last Day Of Payment',
        name: 'lastDateOfPayment',
        isDate: true
    },
    {
        label: 'Status',
        name: 'status'
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns