const columns = [
    {
        label: 'Name',
        name: 'name'
    },
    {
        label: 'Email',
        name: 'email'
    },
    {
        label: 'Phone',
        name: 'phone'
    },
    {
        label: 'Unit',
        name: 'unitName'
    },
    {
        label: 'Unit Label',
        name: 'unitLabel'
    },
    {
        label: 'Deposit (Ksh)',
        name: 'depositPaid',
        isNumber:true
    },
    {
        label: 'Balance (Ksh)',
        name: 'balance',
        isNumber:true
    },
    {
        label: 'Action',
        name: 'action'
    }
]
export default columns